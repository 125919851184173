import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { actions as notification } from '@skunexus/notification';
import Component from './Products';

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
    errorNotification: notification.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
