import {
    CUSTOMERS_TITLE_PREFIX,
    CUSTOMERS_INDEX_ROUTE,
    CUSTOMERS_DETAILS_PATH,
    CUSTOMERS_DETAILS_ROUTE,
    CUSTOMERS_FORM_PATH,
    CUSTOMERS_CREATE_FORM_ROUTE,
    CUSTOMERS_EDIT_FORM_ROUTE,
    CUSTOMERS_ADDRESS_PATH,
    CUSTOMERS_CREATE_ADDRESS_ROUTE,
    CUSTOMERS_EDIT_ADDRESS_ROUTE,
} from './consts';

import { ORDERS_DETAILS_ROUTE } from '../orders/consts';
import { EMPTY_TITLE_CRUMB } from '../constants';

export default () => ({
    customerAddressForm: {
        path: CUSTOMERS_ADDRESS_PATH,
        title: `${CUSTOMERS_TITLE_PREFIX} Address Form`,
        permission: ['customers - addresses.create', 'customers - addresses.update'],
        breadcrumbConfig: { title: 'Add address', isReplaceble: true },
        loader: () => import('@skunexus/customer-address-form'),
        props: () => ({
            detailsPageRoute: CUSTOMERS_DETAILS_ROUTE,
        }),
    },
    customersForm: {
        path: CUSTOMERS_FORM_PATH,
        title: `${CUSTOMERS_TITLE_PREFIX} Form`,
        permission: ['customers.create', 'customers.update'],
        breadcrumbConfig: { title: 'Add Customer', isReplaceble: true },
        loader: () => import('@skunexus/customer-form'),
        props: () => ({
            gridPageRoute: CUSTOMERS_INDEX_ROUTE,
        }),
    },
    customerDetails: {
        path: CUSTOMERS_DETAILS_PATH,
        title: `${CUSTOMERS_TITLE_PREFIX} Details`,
        permission: ['customers.show'],
        breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
        loader: () => import('@skunexus/customer-details'),
        props: () => ({
            gridPageRoute: CUSTOMERS_INDEX_ROUTE,
            updateFormPageRoute: CUSTOMERS_EDIT_FORM_ROUTE,
            addAddressFormPageRoute: CUSTOMERS_CREATE_ADDRESS_ROUTE,
            updateAddressFormPageRoute: CUSTOMERS_EDIT_ADDRESS_ROUTE,
            orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
        }),
    },
    customers: {
        path: CUSTOMERS_INDEX_ROUTE,
        title: `${CUSTOMERS_TITLE_PREFIX}s`,
        permission: ['customers.index'],
        breadcrumbConfig: { title: `${CUSTOMERS_TITLE_PREFIX}s`, clearPath: true },
        loader: () => import('@skunexus/customers-grid'),
        props: () => ({
            detailsPageRoute: CUSTOMERS_DETAILS_ROUTE,
            addFormPageRoute: CUSTOMERS_CREATE_FORM_ROUTE,
            updateFormPageRoute: CUSTOMERS_EDIT_FORM_ROUTE,
        }),
    },
});
