import { useRef } from 'react';
import { find, debounce } from 'lodash';

import useForm from './hooks/useForm';

const useAddProductsModal = ({ items: products = [], products: addedProducts = [], onSearch, onConfirm, onClose }) => {
    const inputRef = useRef(null);

    const [{ formRef, items }, { handleChangeItemQty, handleSelectItem, handleUnselectItem, handleSubmit }] = useForm({
        products,
        onConfirm,
    });

    const getSearchText = () => {
        if (inputRef.current) {
            return inputRef.current.value;
        }

        return '';
    };

    const handleCloseModal = () => {
        onClose();

        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const handleSearch = debounce((value) => {
        onSearch(value);
    }, 500);

    const handleConfirm = () => {
        formRef.current.handleSubmit();

        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    return [
        {
            inputRef,
            searchText: getSearchText(),
            formRef,
            items: items.map(({ id, ...item }) => ({ ...item, id, isAdded: !!find(addedProducts, ['id', id]) })),
        },
        {
            handleCloseModal,
            handleSearch,
            handleChangeItemQty,
            handleSelectItem,
            handleUnselectItem,
            handleConfirm,
            handleSubmit,
        },
    ];
};

export default useAddProductsModal;
