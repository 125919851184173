import React from 'react';

import { Button, SmstIcon } from '@skunexus/component-catalog';
import useRemoveButton from './useRemoveButton';

import styles from './styles.module.css';

function RemoveButton(props) {
    const [{ isConfirmationVisible }, { handleRemove, handleConfirmRemove, handleCancleRemove }] =
        useRemoveButton(props);

    return (
        <>
            <span className={styles.wrapper}>
                <SmstIcon className={styles.checkIcon} icon="CheckSquare" />
                <Button
                    className={styles.trashButton}
                    onlyIcon
                    icon="Trash3"
                    color="link"
                    size="lg"
                    onClick={handleRemove}
                />
            </span>
            {isConfirmationVisible && (
                <div className={styles.confirmationWrapper}>
                    <span>Are you sure?</span>
                    <Button color="primary" size="sm" onClick={handleConfirmRemove}>
                        Yes
                    </Button>
                    <Button size="sm" outline onClick={handleCancleRemove}>
                        No
                    </Button>
                </div>
            )}
        </>
    );
}

RemoveButton.propTypes = {};

RemoveButton.defaultProps = {};

export default RemoveButton;
