/* eslint-disable camelcase */
import React from 'react';
import classnames from 'classnames';
import { get, find, findIndex, has, flatten, isEmpty, reduce } from 'lodash';
import { ProductTag, SmstIcon } from '@skunexus/component-catalog';

import styles from '../styles.module.css';

const NO_PARENT_ITEMS = 'orphan';

const useCancelledItems = ({ items, routes = {} }) => {
    const { productDetailsPageRoute } = routes;

    const getParentData = (item) => {
        const { parentRelatedProduct = {}, customValues = {} } = item;
        const { id: parentId, name: parentName, sku: parentSku, weight: parentWeight } = parentRelatedProduct;
        const parentQty = get(find(customValues, ['custom_field_id', 'parent_qty']), 'value', 0);

        return {
            qty: parentQty,
            sku: parentSku,
            weight: parentWeight,
            productName: parentName,
            productId: parentId,
        };
    };

    const getChildrenData = (item) => {
        const { product_id, qty, relatedProduct } = item;
        const { name, weight, sku } = relatedProduct;
        return [
            {
                qty,
                sku,
                weight,
                productName: name,
                productId: product_id,
            },
        ];
    };

    // eslint-disable-next-line no-shadow
    const getFormattedItems = (items = []) => {
        const groupedItems = reduce(
            items,
            (result, item) => {
                const { parentRelatedProduct = {}, product_id } = item;

                const hasParent = !isEmpty(parentRelatedProduct) && product_id !== get(parentRelatedProduct, 'id');

                if (hasParent) {
                    const { id: parentId } = parentRelatedProduct;

                    let parentIndex = findIndex(result, ({ parent }) => parent.productId === parentId);
                    if (parentIndex < 0) {
                        result.push({
                            parent: getParentData(item),
                            children: [],
                        });
                    }
                    parentIndex = findIndex(result, ({ parent }) => parent.productId === parentId);
                    // eslint-disable-next-line no-param-reassign
                    result[parentIndex].children = [...result[parentIndex].children, ...getChildrenData(item)];
                } else {
                    let parentIndex = findIndex(result, ({ parent }) => parent.productId === NO_PARENT_ITEMS);
                    if (parentIndex < 0) {
                        result.push({
                            parent: {
                                productId: NO_PARENT_ITEMS,
                            },
                            children: [],
                        });
                    }

                    parentIndex = findIndex(result, ({ parent }) => parent.productId === NO_PARENT_ITEMS);
                    // eslint-disable-next-line no-param-reassign
                    result[parentIndex].children = [...result[parentIndex].children, ...getChildrenData(item)];
                }

                return result;
            },
            [],
        );

        return flatten(
            groupedItems
                .filter(({ children = [] }) => children.length)
                .map(({ parent = {}, children = [] }) => {
                    if (parent.productId === NO_PARENT_ITEMS) {
                        return [...children];
                    }

                    return [
                        {
                            ...parent,
                        },
                        ...children.map((child) => ({ isChild: has(parent, 'productName'), ...child })),
                    ];
                }),
        );
    };

    const formattedItems = getFormattedItems(items);

    const getGridProps = () => ({
        id: 'order-items',
        data: {
            values: formattedItems,
            columns: [
                {
                    field: 'qty',
                    label: 'Qty',
                    template: ({ originalValue: { value = '', row: { isChild = false } = {} } = {} }) => (
                        <>
                            {isChild && (
                                <span className="float-left mr-2 d-block" style={{ marginTop: '-2px' }}>
                                    <SmstIcon icon="ArrowReturnRight" />
                                </span>
                            )}
                            {value}
                        </>
                    ),
                },
                {
                    field: 'sku',
                    label: 'Product',
                    template: ({
                        originalValue: {
                            value = '',
                            row: { isChild = false, productId = '', productName = '' } = {},
                        } = {},
                    }) => (
                        <div className={classnames('my-2', { [styles.isChild]: isChild, [styles.isParent]: !isChild })}>
                            <ProductTag id={productId} name={productName} sku={value} route={productDetailsPageRoute} />
                        </div>
                    ),
                },
                {
                    field: 'weight',
                    label: 'Weight',
                    template: ({ originalValue: { value = '', row: { isChild = false, qty } = {} } = {} }) => (
                        <div className={classnames({ [styles.isChild]: isChild, [styles.isParent]: !isChild })}>
                            {(value * qty).toFixed(2)} lb
                        </div>
                    ),
                },
            ],
            itemsCount: formattedItems.length,
            config: {
                withHeaderBar: false,
                withFooter: false,
            },
        },
        getData: () => {},
    });

    return [
        {
            title: 'Order Items',
            itemsCount: formattedItems.length,
        },
        { getGridProps },
    ];
};

export default useCancelledItems;
