import { selectors } from '@skunexus/utils';
import UserModel from '../../../UserModel';

import {
    TITLE_PREFIX,
    INDEX_TITLE,
    PRODUCTS_INDEX_ROUTE,
    PRODUCTS_DETAILS_ROUTE,
    PRODUCTS_FORM_PATH,
    PRODUCTS_FORM_ROUTE,
    PRODUCTS_STOCK_PATH,
    PRODUCTS_STOCK_FORM_ROUTE,
    PRODUCTS_INTERNAL_TRANSFER_PATH,
} from './consts';

import { EMPTY_TITLE_CRUMB } from '../constants';

import { ORDERS_DETAILS_ROUTE, ORDERS_DETAILS_PATH } from '../orders/consts';
import { USERS_DETAILS_ROUTE } from '../users/consts';
import { WAREHOUSES_DETAILS_PATH } from '../warehouses/consts';
import withProductsTabsWrapper from './components/withProductsTabsWrapper';

export default (app) => {
    const tabs = app.productDetailsTabs.getAll();

    const routers = {
        products: {
            path: PRODUCTS_INDEX_ROUTE,
            title: INDEX_TITLE,
            permission: ['products.index'],
            breadcrumbConfig: { title: INDEX_TITLE, clearPath: true },
            loader: () => import('@skunexus/products'),
            props: () => ({
                detailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                addFormPageRoute: PRODUCTS_FORM_ROUTE,
                updateFormPageRoute: PRODUCTS_FORM_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_PATH,
            }),
        },
        productForm: {
            path: PRODUCTS_FORM_PATH,
            title: `${INDEX_TITLE} > Add Product`,
            permission: ['products.index'],
            breadcrumbConfig: { title: 'Add Product', isReplaceble: true },
            loader: () => import('@skunexus/product-form'),
            props: () => ({
                productsPageRoute: PRODUCTS_INDEX_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                domain: 'product',
            }),
        },
        productDetailsStockInternalTransfer: {
            path: PRODUCTS_INTERNAL_TRANSFER_PATH,
            title: `${TITLE_PREFIX} Internal Stock Transfer`,
            permission: tabs.stock.permission,
            breadcrumbConfig: {
                title: 'Internal Stock Transfer',
                isReplaceble: true,
            },
            props: () => ({
                indexPageRoute: PRODUCTS_STOCK_PATH,
                strictMode: true,
            }),
            loader: () => import('@skunexus/product-details-stock-internal-transfer'),
        },
    };

    if (tabs.overview) {
        routers.productDetailsOverview = {
            path: tabs.overview.link,
            title: tabs.overview.title,
            permission: tabs.overview.permission,
            breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true, isOverview: true },
            props: (state) => ({
                formPageRoute: PRODUCTS_FORM_ROUTE,
                withImages: false,
                idNameMap: selectors.getIDNameMap(state.app),
            }),
            loader: () => import('@skunexus/product-details'),
            render: withProductsTabsWrapper(app),
        };
    }

    if (tabs.stock) {
        routers.productDetailsStock = {
            path: tabs.stock.link,
            title: tabs.stock.title,
            permission: tabs.stock.permission,
            breadcrumbConfig: { title: tabs.stock.label, isReplaceble: true, isTab: true },
            props: (state) => ({
                formPageRoute: PRODUCTS_STOCK_FORM_ROUTE,
                internalTransferPageRoute: PRODUCTS_INTERNAL_TRANSFER_PATH,
                idNameMap: selectors.getIDNameMap(state.app),
            }),
            loader: () => import('@skunexus/product-details-stock'),
            render: withProductsTabsWrapper(app),
        };
    }
    if (tabs.allocations) {
        routers.productDetailsAllocations = {
            path: tabs.allocations.link,
            title: tabs.allocations.title,
            permission: tabs.allocations.permission,
            breadcrumbConfig: { title: tabs.allocations.label, isReplaceble: true, isTab: true },
            props: (state) => ({
                orderDetailsRoute: ORDERS_DETAILS_ROUTE,
                idNameMap: selectors.getIDNameMap(state.app),
            }),
            loader: () => import('@skunexus/product-details-allocations'),
            render: withProductsTabsWrapper(app),
        };
    }
    if (tabs.adjustments) {
        routers.productDetailsAdjustments = {
            path: tabs.adjustments.link,
            title: tabs.adjustments.title,
            permission: tabs.adjustments.permission,
            breadcrumbConfig: { title: tabs.adjustments.label, isTab: true, isReplaceble: true },
            props: (state) => ({
                userDetailsRoute: USERS_DETAILS_ROUTE,
                idNameMap: selectors.getIDNameMap(state.app),
            }),
            loader: () => import('@skunexus/product-details-adjustments'),
            render: withProductsTabsWrapper(app),
        };
    }
    if (tabs.barcodes) {
        routers.productDetailsBarCodes = {
            path: tabs.barcodes.link,
            title: tabs.barcodes.title,
            permission: tabs.barcodes.permission,
            breadcrumbConfig: { title: tabs.barcodes.label, isReplaceble: true, isTab: true },
            props: () => ({}),
            loader: () => import('@skunexus/product-details-barcodes'),
            render: withProductsTabsWrapper(app),
        };
    }
    if (tabs.reservations) {
        routers.productDetailsReservations = {
            path: tabs.reservations.link,
            title: tabs.reservations.title,
            permission: tabs.reservations.permission,
            breadcrumbConfig: { title: tabs.reservations.label, isReplaceble: true, isTab: true },
            props: () => ({
                orderDetailsRoute: ORDERS_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/product-details-reservations'),
            render: withProductsTabsWrapper(app),
        };
    }
    if (tabs.kitting) {
        routers.productDetailsKitting = {
            path: tabs.kitting.link,
            title: tabs.kitting.title,
            permission: tabs.kitting.permission,
            breadcrumbConfig: { title: tabs.kitting.label, isReplaceble: true, isTab: true },
            props: () => ({
                permissions: {
                    create: true,
                    deactive: false,
                    delete: true,
                },
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/product-details-kitting'),
            render: withProductsTabsWrapper(app),
        };
    }
    if (tabs.media) {
        routers.productDetailsMedia = {
            name: 'media',
            path: tabs.media.link,
            title: tabs.media.title,
            permission: tabs.media.permission,
            breadcrumbConfig: { title: tabs.media.label, isReplaceble: true, isTab: true },
            props: () => ({
                domain: 'product',
                entityName: 'Product',
                basePath: 'products',
            }),
            loader: () => import('@skunexus/media'),
            render: withProductsTabsWrapper(app),
        };
    }
    if (tabs.cycleCount) {
        routers.productDetailsCycleCount = {
            name: 'cycleCount',
            path: tabs.cycleCount.link,
            title: tabs.cycleCount.title,
            permission: tabs.cycleCount.permission,
            breadcrumbConfig: { title: tabs.cycleCount.label, isReplaceble: true, isTab: true },
            props: () => ({
                warehouseDetailsPageRoute: WAREHOUSES_DETAILS_PATH,
                canAssignToGroup: UserModel.getCurrentUser().hasAnyPermissions('cycle-counts.assign count group'),
            }),
            loader: () => import('@skunexus/cycle-count-product-details-tab'),
            render: withProductsTabsWrapper(app),
        };
    }

    return routers;
};
