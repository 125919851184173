import React from 'react';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';
import { Container, Col } from 'reactstrap';

import ResolvePurchaseOrder from '../ResolvePurchaseOrder';

import { PURCHASE_ORDER_STATUSES } from '../../constants';

import styles from './styles.module.css';

function Module({ details, statuses }) {
    const orderState = get(find(statuses, ['state', get(details, 'state')]), 'state', '');

    if (orderState && orderState === PURCHASE_ORDER_STATUSES.ERROR) {
        return (
            <Col xs="12" sm="auto">
                <Container className={`p-4 ${styles.resolveWrapper}`}>
                    <ResolvePurchaseOrder />
                </Container>
            </Col>
        );
    }

    return null;
}

Module.propTypes = {
    details: PropTypes.object.isRequired,
    statuses: PropTypes.array.isRequired,
};

export default Module;
