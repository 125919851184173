import { useMemo } from 'react';

import { useAssginedItems, useCancelledItems } from './hooks';

const useDecidedItems = ({ order = {}, items = [], routes = {} }) => {
    const { state } = order;

    const isCancelledState = useMemo(() => state === 'Cancelled', [state]);

    const [{ title: assignedTitle, itemsCount: assignedItemsCount }, { getGridProps: getAssignedGridProps }] =
        useAssginedItems({
            items,
            routes,
        });
    const [{ title: cancelledTitle, itemsCount: cancelledItemsCount }, { getGridProps: getCancelledGridProps }] =
        useCancelledItems({
            items,
            routes,
        });

    return [
        {
            title: isCancelledState ? cancelledTitle : assignedTitle,
            itemsCount: isCancelledState ? cancelledItemsCount : assignedItemsCount,
        },
        { getGridProps: isCancelledState ? getCancelledGridProps : getAssignedGridProps },
    ];
};

export default useDecidedItems;
