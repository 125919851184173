import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner, FormElements } from '@skunexus/component-catalog';

import { constants } from '@skunexus/order-details';

import useVendorPrice from './useVendorPrice';

import styles from './styles.module.css';

const { DROPSHIP_DELIVERY_METHOD } = constants;

function VendorPrice(props) {
    const { deliveryMethod, itemId, vendorId } = props;
    const [{ isLoading, value }, { handleChange }] = useVendorPrice(props);
    if (deliveryMethod !== DROPSHIP_DELIVERY_METHOD) return null;

    return (
        <div className={styles.wrapper}>
            {isLoading && <Spinner className={styles.spinner} size="sm" />}
            <FormElements.FormLabel label="Vendor Price" />
            <input
                type="number"
                name={`vendorPrice[${itemId}]`}
                onChange={handleChange}
                disabled={!vendorId}
                value={value}
                className={classnames({ [styles.isInvalid]: !value })}
            />
        </div>
    );
}

VendorPrice.propTypes = {
    deliveryMethod: PropTypes.string,
    vendorId: PropTypes.string,
};

VendorPrice.defaultProps = {
    deliveryMethod: '',
    vendorId: '',
};

export default VendorPrice;
