import { useState } from 'react';
import { get, head, isEmpty } from 'lodash';
import * as API from './api';

const useVendorProduct = () => {
    const [vendorProduct, setVendorProduct] = useState({
        data: {},
        isLoading: false,
        error: null,
    });

    const fetchVendorProduct = async ({ productId, vendorId }) => {
        setVendorProduct((state) => ({
            ...state,
            isLoading: true,
            error: null,
        }));
        try {
            const result = await API.fetchVendorProduct({ productId, vendorId });

            const data = get(result, 'data.data.vendorProduct.grid.rows');

            if (!data) {
                throw new Error(`Couldn't load vendor product price.`);
            }

            if (isEmpty(data)) {
                throw new Error('No vendor product price found.');
            }

            setVendorProduct((state) => ({
                ...state,
                data: head(data),
                isLoading: false,
            }));

            return head(data);
        } catch (e) {
            setVendorProduct((state) => ({
                ...state,
                isLoading: false,
                error: e,
            }));

            return null;
        }
    };

    return [
        {
            vendorProduct: vendorProduct.data,
            error: vendorProduct.error,
            isLoading: vendorProduct.isLoading,
        },
        { fetchVendorProduct },
    ];
};

export default useVendorProduct;
