/* eslint-disable camelcase */
import { useState } from 'react';
import { get, find } from 'lodash';
import * as API from './api';

const useItems = () => {
    const [items, setItems] = useState({
        data: [],
        isLoading: false,
        error: null,
    });

    const getPriceForProduct = ({ products, productId }) => {
        const productData = find(products, ['id', productId]);
        const { customValues = [] } = productData;

        return get(find(customValues, ['custom_field_id', 'price']), 'value', 0);
    };

    const fetchItems = async (id) => {
        setItems((state) => ({
            ...state,
            isLoading: true,
            error: null,
        }));
        try {
            const result = await API.fetchLineItems(id);
            const rows = get(result, `data.data.purchaseOrder.details.lineItems.rows`);

            if (!rows) {
                throw new Error(`Couldn't load items`);
            }

            const productsIds = rows.map(({ product_id }) => product_id);

            const productsResult = await API.fetchProducts(productsIds);
            const products = get(productsResult, `data.data.product.grid.rows`);

            const rowsWithProductPrice = rows.map((item) => {
                const { product_id, product = {} } = item;

                return {
                    ...item,
                    product_id,
                    product: {
                        ...product,
                        price: getPriceForProduct({ products, productId: product_id }),
                    },
                };
            });

            setItems((state) => ({
                ...state,
                data: rowsWithProductPrice,
                isLoading: false,
            }));

            return rowsWithProductPrice;
        } catch (e) {
            setItems((state) => ({
                ...state,
                isLoading: false,
                error: e,
            }));

            return null;
        }
    };

    const reset = () => {
        setItems((state) => ({
            ...state,
            data: [],
            isLoading: false,
            error: null,
        }));
    };

    return [
        {
            items: items.data,
            error: items.error,
            isLoading: items.isLoading,
        },
        { fetchItems, reset },
    ];
};

export default useItems;
