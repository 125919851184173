/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';

import ParentItem from './components/ParentItem';
import ChildItem from './components/ChildItem';

function Item(props) {
    const { item } = props;

    const { isChild = false } = item;

    if (isChild) return <ChildItem {...props} />;

    return <ParentItem {...props} />;
}

Item.propTypes = {
    item: PropTypes.shape({
        isChild: PropTypes.bool,
    }),
};

Item.defaultProps = {
    item: { isChild: false },
};

export default Item;
