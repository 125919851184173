import withSettingsTabsWrapper from './components/withSettingsTabsWrapper';

export default (app) => ({
    // DISABLED FOR NOW, UNTIL WE START TO USE IT
    // currencyForm: {
    //     path: app.settingsGlobalTabs.getItem('currency').link,
    //     title: app.settingsGlobalTabs.getItem('currency').title,
    //     permission: app.settingsGlobalTabs.getItem('currency').permission,
    //     props: () => ({}),
    //     loader: () => import('@skunexus/global-currency'),
    //     render: withSettingsTabsWrapper(app),
    // },
    companySettings: {
        path: app.settingsGlobalTabs.getItem('company').link,
        title: app.settingsGlobalTabs.getItem('company').title,
        permission: app.settingsGlobalTabs.getItem('company').permission,
        props: () => ({}),
        loader: () => import('@skunexus/company-settings'),
        render: withSettingsTabsWrapper(app),
    },
    emailNotifcations: {
        path: app.settingsGlobalTabs.getItem('emailNotifcations').link,
        title: app.settingsGlobalTabs.getItem('emailNotifcations').title,
        permission: app.settingsGlobalTabs.getItem('emailNotifcations').permission,
        props: () => ({}),
        loader: () => import('@skunexus/email-notifcations-settings'),
        render: withSettingsTabsWrapper(app),
    },
    productAdjustmentStockReasons: {
        path: app.settingsGlobalTabs.getItem('adjustmentStockReasons').link,
        title: app.settingsGlobalTabs.getItem('adjustmentStockReasons').title,
        permission: app.settingsGlobalTabs.getItem('adjustmentStockReasons').permission,
        props: () => ({}),
        loader: () => import('@skunexus/product-details-adjustment-stock-reasons'),
        render: withSettingsTabsWrapper(app),
    },
});
