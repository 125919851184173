import {
    TITLE_PREFIX,
    PUT_AWAY_TITLE_PREFIX,
    RI_INDEX_ROUTE,
    RI_ITEMS_INDEX_ROUTE,
    RI_PUT_AWAY_INDEX_ROUTE,
    RI_PUT_AWAY_FINALIZED_INDEX_ROUTE,
} from './consts';

export default () => ({
    receiveInventory: {
        id: 'receiveInventory',
        label: TITLE_PREFIX,
        link: RI_INDEX_ROUTE,
        order: 10,
    },
    itemsReceived: {
        id: 'itemsReceived',
        label: 'Items Received',
        link: RI_ITEMS_INDEX_ROUTE,
        order: 20,
    },
    scanned: {
        id: 'scanned',
        label: `Scanned Items to ${PUT_AWAY_TITLE_PREFIX}`,
        link: RI_PUT_AWAY_INDEX_ROUTE,
        order: 30,
    },
    finalized: {
        id: 'finalized',
        label: 'Finalized',
        link: RI_PUT_AWAY_FINALIZED_INDEX_ROUTE,
        order: 40,
    },
});
