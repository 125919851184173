import { useState, useEffect } from 'react';
import { get } from 'lodash';

import { ajaxExceptionMessage } from '@skunexus/utils';

import useSearch from './hooks/useSearch';
import useGroupedProducts from './hooks/useGroupedProducts';

const useProducts = ({ formData = {}, onChangeField = () => {}, errorNotification = () => {} }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const [{ products: foundProducts, error, isLoading: isSearching }, { fetchProducts, reset: resetSearch }] =
        useSearch();
    const [{ isLoading }, { getGroupedProducts }] = useGroupedProducts({ errorNotification });

    useEffect(() => {
        if (error) {
            errorNotification(ajaxExceptionMessage(error));
        }
    }, [error]);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const handleCloseModal = () => {
        resetSearch();
        closeModal();
    };

    const handleConfirm = async (items) => {
        handleCloseModal();
        const groupedItems = await getGroupedProducts(items, get(formData, 'line_items', []));

        onChangeField({ name: 'line_items', value: groupedItems });
    };

    const getModalProps = () => ({
        isOpen: isModalOpen,
        isLoading: isSearching,
        items: foundProducts,
        products: get(formData, 'line_items', []),
        onConfirm: handleConfirm,
        onSearch: fetchProducts,
        onClose: handleCloseModal,
        errorNotification,
    });

    const getProductItemsProps = () => ({
        isLoading,
        products: get(formData, 'line_items', []),
        onChangeProducts: onChangeField,
        errorNotification,
    });

    return [
        { products: get(formData, 'line_items', []) },
        { handleOpenModal: openModal, getModalProps, getProductItemsProps },
    ];
};

export default useProducts;
