import React from 'react';
import useQtyInput from './useQtyInput';

function QtyInput(props) {
    const { errorNotification, max, ...restProps } = props;
    const [{ value, isError }, { handleChange }] = useQtyInput(props);

    return (
        <div className="text-danger w-100">
            <input {...restProps} className={isError ? 'is-invalid' : ''} onChange={handleChange} value={value} />
            {isError && (
                <div style={{ display: 'block' }} className="mt-0 invalid-feedback">
                    This field is required
                </div>
            )}
        </div>
    );
}

QtyInput.propTypes = {};

QtyInput.defaultProps = {};

export default QtyInput;
