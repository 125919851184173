export default {
    name: 'customProductGrid',
    before: [],
    after: [],
    setup: (app) => {
        const productsRoute = app.routes.getItem('products');
        if (productsRoute) {
            productsRoute.loader = () => import('./packages/ProductsGrid/core');
        }
    },
};
