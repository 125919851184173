import { connect } from 'react-redux';
import { actions as notification } from '@skunexus/notification';

import Components from './ClientField';

const mapDispatchToProps = {
    errorNotification: notification.error,
    successNotification: notification.success,
};

export default connect(() => {}, mapDispatchToProps)(Components);
