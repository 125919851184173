import { connect } from 'react-redux';

import { selectors } from '@skunexus/purchase-order-overview';

import Module from './Module';

const { getDetails, getStatuses } = selectors;

const mapStateToProps = (state) => ({
    details: getDetails(state),
    statuses: getStatuses(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Module);
