import React from 'react';
import { contexts } from '@skunexus/utils';

const { ConfigContextProvider } = contexts;
const contextProviders = [ConfigContextProvider];

function AppContextProvider({ children }) {
    return contextProviders.reduceRight((memo, ContextProvider) => <ContextProvider>{memo}</ContextProvider>, children);
}

export default AppContextProvider;
