/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { get, find, findIndex, has, startCase, flatten, isEmpty, reduce, sumBy } from 'lodash';
import { ProductTag, SmstIcon } from '@skunexus/component-catalog';
import { constants } from '@skunexus/order-details';

import styles from '../styles.module.css';

const { SHIPMENT_DELIVERY_METHOD, PICKUP_DELIVERY_METHOD, DROPSHIP_DELIVERY_METHOD } = constants;
const NO_PARENT_ITEMS = 'orphan';

const useAssginedItems = ({ items, routes = {} }) => {
    const { productDetailsPageRoute } = routes;

    const getFulfillmentData = ({
        fulfillmentType = SHIPMENT_DELIVERY_METHOD,
        relatedFulfillment,
        relatedPurchaseOrder,
    }) => {
        const {
            id: fulfillmentId = '',
            group_id: fulfillmentGroupId = '',
            label: fulfillmentLabel = '',
        } = relatedFulfillment;

        const { id: purchaseOrderId = '', label: purchaseOrderLabel = '' } = relatedPurchaseOrder;

        if (fulfillmentType !== DROPSHIP_DELIVERY_METHOD) {
            return {
                id: fulfillmentGroupId || fulfillmentId,
                label: fulfillmentLabel,
            };
        }

        return {
            id: purchaseOrderId,
            label: purchaseOrderLabel,
        };
    };

    const getFulfillmentUrl = ({ id, type, isGrouped }) => {
        let pageRoute = '';

        if (type === SHIPMENT_DELIVERY_METHOD) {
            pageRoute = routes.fulfillmentShipDetailsPageRoute;
            if (isGrouped) {
                pageRoute = routes.fulfillmentShipGroupDetailsPageRoute;
            }
        } else if (type === PICKUP_DELIVERY_METHOD) {
            pageRoute = routes.fulfillmentPickupDetailsPageRoute;
            if (isGrouped) {
                pageRoute = routes.fulfillmentPickupGroupDetailsPageRoute;
            }
        } else if (type === DROPSHIP_DELIVERY_METHOD) {
            pageRoute = routes.purchaseOrderPageRoute;
        }

        return `${pageRoute}/${id}`;
    };

    const getParentData = (item) => {
        const { parentRelatedProduct = {}, customValues = {} } = item;
        const { id: parentId, name: parentName, sku: parentSku, weight: parentWeight } = parentRelatedProduct;
        const parentQty = get(find(customValues, ['custom_field_id', 'parent_qty']), 'value', 0);

        return {
            qty: parentQty,
            sku: parentSku,
            weight: parentWeight,
            productName: parentName,
            productId: parentId,
            location: '',
            itemPrice: '',
            fulfillment: {},
        };
    };

    const getChildrenData = (item) => {
        const { decidedItems = [], product_id, customValues = {}, relatedProduct = {} } = item;
        const { name, weight, sku } = relatedProduct;
        const itemPrice = get(find(customValues, ['custom_field_id', 'item_price']), 'value', 0);

        return flatten(
            // eslint-disable-next-line no-shadow
            decidedItems.map((item) => {
                const { name: fulfillmentType, decisions = [] } = item;

                return decisions.map(({ qty, label, relatedFulfillment, relatedPurchaseOrder }) => {
                    const { state: fulfillmentState = '', group_id } = relatedFulfillment;

                    const { id: fulfillmentId, label: fulfillmentLabel } = getFulfillmentData({
                        fulfillmentType,
                        relatedFulfillment,
                        relatedPurchaseOrder,
                    });

                    return {
                        qty,
                        sku,
                        weight,
                        productName: name,
                        productId: product_id,
                        location: `${fulfillmentType} - ${label}`,
                        itemPrice,
                        totalPrice: itemPrice * qty,
                        fulfillment: {
                            id: fulfillmentId,
                            label: fulfillmentLabel,
                            type: fulfillmentType,
                            state: fulfillmentState,
                            isGrouped: !!group_id,
                        },
                    };
                });
            }),
        );
    };

    // eslint-disable-next-line no-shadow
    const getFormattedItems = (items = []) => {
        const groupedItems = reduce(
            items,
            (result, item) => {
                const { parentRelatedProduct = {}, product_id } = item;

                const hasParent = !isEmpty(parentRelatedProduct) && product_id !== get(parentRelatedProduct, 'id');

                if (hasParent) {
                    const { id: parentId } = parentRelatedProduct;

                    let parentIndex = findIndex(result, ({ parent }) => parent.productId === parentId);
                    if (parentIndex < 0) {
                        result.push({
                            parent: getParentData(item),
                            children: [],
                        });
                    }
                    parentIndex = findIndex(result, ({ parent }) => parent.productId === parentId);
                    // eslint-disable-next-line no-param-reassign
                    result[parentIndex].children = [...result[parentIndex].children, ...getChildrenData(item)];
                } else {
                    let parentIndex = findIndex(result, ({ parent }) => parent.productId === NO_PARENT_ITEMS);
                    if (parentIndex < 0) {
                        result.push({
                            parent: {
                                productId: NO_PARENT_ITEMS,
                            },
                            children: [],
                        });
                    }

                    parentIndex = findIndex(result, ({ parent }) => parent.productId === NO_PARENT_ITEMS);
                    // eslint-disable-next-line no-param-reassign
                    result[parentIndex].children = [...result[parentIndex].children, ...getChildrenData(item)];
                }

                return result;
            },
            [],
        );

        return flatten(
            groupedItems
                .filter(({ children = [] }) => children.length)
                .map(({ parent = {}, children = [] }) => {
                    if (parent.productId === NO_PARENT_ITEMS) {
                        return [...children];
                    }

                    return [
                        {
                            ...parent,
                            totalPrice: sumBy(children, ({ totalPrice = 0 }) => totalPrice),
                        },
                        ...children.map((child) => ({ isChild: has(parent, 'productName'), ...child })),
                    ];
                }),
        );
    };

    const formattedItems = getFormattedItems(items);

    const getGridProps = () => ({
        id: 'assigned-for-fulfillment',
        data: {
            values: formattedItems,
            columns: [
                {
                    field: 'qty',
                    label: 'Qty',
                    template: ({ originalValue: { value = '', row: { isChild = false } = {} } = {} }) => (
                        <>
                            {isChild && (
                                <span className="float-left mr-2 d-block" style={{ marginTop: '-2px' }}>
                                    <SmstIcon icon="ArrowReturnRight" />
                                </span>
                            )}
                            {value}
                        </>
                    ),
                },
                {
                    field: 'sku',
                    label: 'Product',
                    template: ({
                        originalValue: {
                            value = '',
                            row: { isChild = false, productId = '', productName = '' } = {},
                        } = {},
                    }) => (
                        <div className={classnames('my-2', { [styles.isChild]: isChild, [styles.isParent]: !isChild })}>
                            <ProductTag id={productId} name={productName} sku={value} route={productDetailsPageRoute} />
                        </div>
                    ),
                },
                {
                    field: 'location',
                    label: 'Location',
                    template: ({ originalValue: { value = '', row: { isChild = false } = {} } = {} }) => (
                        <div className={classnames({ [styles.isChild]: isChild, [styles.isParent]: !isChild })}>
                            {value}
                        </div>
                    ),
                },
                {
                    field: 'weight',
                    label: 'Weight',
                    template: ({ originalValue: { value = '', row: { isChild = false, qty } = {} } = {} }) => (
                        <div className={classnames({ [styles.isChild]: isChild, [styles.isParent]: !isChild })}>
                            {(value * qty).toFixed(2)} lb
                        </div>
                    ),
                },
                {
                    field: 'fulfillment.label',
                    label: 'Fulfillment/Purchase Order',
                    template: ({ originalValue: { value = '', row: { fulfillment = {}, isChild = false } } = {} }) => (
                        <div className={classnames({ [styles.isChild]: isChild, [styles.isParent]: !isChild })}>
                            <Link to={{ pathname: `${getFulfillmentUrl(fulfillment)}` }}>{value}</Link>
                        </div>
                    ),
                },
                {
                    field: 'fulfillment.state',
                    label: 'Status',
                    template: ({ originalValue: { value = '', row: { isChild = false } } = {} }) => (
                        <div className={classnames({ [styles.isChild]: isChild, [styles.isParent]: !isChild })}>
                            {startCase(value)}
                        </div>
                    ),
                },
                {
                    field: 'itemPrice',
                    label: 'Item Price',
                    template: ({ originalValue: { value = '', row: { isChild = false } } = {} }) => (
                        <div
                            className={classnames(styles.price, {
                                [styles.isChild]: isChild,
                                [styles.isParent]: !isChild,
                            })}
                        >
                            {value ? value.toFixed(2) : ''}
                        </div>
                    ),
                },
                {
                    field: 'totalPrice',
                    label: 'Total Price',
                    template: ({ originalValue: { value = '', row: { isChild = false } } = {} }) => (
                        <div
                            className={classnames(styles.price, {
                                [styles.isChild]: isChild,
                                [styles.isParent]: !isChild,
                            })}
                        >
                            {value ? value.toFixed(2) : ''}
                        </div>
                    ),
                },
            ],
            itemsCount: formattedItems.length,
            config: {
                withHeaderBar: false,
                withFooter: false,
            },
        },
        getData: () => {},
    });

    return [
        {
            title: 'Products Assigned for Fulfillment',
            itemsCount: formattedItems.length,
        },
        { getGridProps },
    ];
};

export default useAssginedItems;
