import values from 'lodash/values';
import get from 'lodash/get';
import {
    SHIP_TITLE_PREFIX,
    SHIP_ALL_ROUTE,
    SHIP_BATCHING_ROUTE,
    SHIP_CANCELLED_ROUTE,
    SHIP_DASHBOARD_ROUTE,
    SHIP_DISPATCHED_ROUTE,
    SHIP_FINALIZED_ROUTE,
    SHIP_GROUP_PICK_ROUTE,
    SHIP_HOSPITAL_ROUTE,
    SHIP_PACK_ROUTE,
    SHIP_PICK_ROUTE,
    SHIP_PRINT_LOGS_ROUTE,
    SHIP_PRINTED_ROUTE,
} from './consts';

import { firstAllowedLink, combinePermissions } from '../../../utils/helpers';

export default (app) => {
    const tabs = {
        main: {
            id: 'dashboard',
            label: 'Dashboard',
            title: `${SHIP_TITLE_PREFIX}`,
            link: SHIP_DASHBOARD_ROUTE,
            permission: 'fulfillments.index',
            order: 10,
        },
        dispatched: {
            id: 'dispatched',
            label: 'Dispatched',
            title: `${SHIP_TITLE_PREFIX} > Dispatched`,
            link: SHIP_DISPATCHED_ROUTE,
            permission: 'fulfillments.index',
            order: 60,
        },
        finalized: {
            id: 'finalized',
            label: 'Finalized',
            title: `${SHIP_TITLE_PREFIX} > Finalized`,
            permission: 'fulfillments.index',
            link: SHIP_FINALIZED_ROUTE,
            order: 70,
        },
        cancelled: {
            id: 'Cancelled',
            label: 'Cancelled',
            title: `${SHIP_TITLE_PREFIX} > Cancelled`,
            permission: 'fulfillments.index',
            link: SHIP_CANCELLED_ROUTE,
            order: 80,
        },
        all: {
            id: 'allShipments',
            label: 'All Shipments',
            title: `${SHIP_TITLE_PREFIX} > All Shipments`,
            permission: 'fulfillments.index',
            link: SHIP_ALL_ROUTE,
            order: 90,
        },
        hospital: {
            id: 'hospital',
            label: 'Hospital',
            title: `${SHIP_TITLE_PREFIX} > Hospital`,
            permission: 'fulfillments.hospital',
            link: SHIP_HOSPITAL_ROUTE,
            order: 100,
        },
        printLogs: {
            id: 'printLogs',
            label: 'Print Logs',
            title: `${SHIP_TITLE_PREFIX} > Print Logs`,
            link: SHIP_PRINT_LOGS_ROUTE,
            position: 'right',
            permission: 'fulfillments.print history',
            order: 100,
        },
    };

    const batchEnabled = get(app.general.getItem('modules'), 'fulfillment.batchEnable', false);
    if (batchEnabled) {
        tabs.batching = {
            id: 'batching',
            label: 'Batching',
            title: `${SHIP_TITLE_PREFIX} > Batching`,
            link: SHIP_BATCHING_ROUTE,
            permission: 'fulfillments.picking',
            order: 25,
        };
        tabs.printed = {
            id: 'printed',
            label: 'Printed',
            title: `${SHIP_TITLE_PREFIX} > Printed`,
            link: SHIP_PRINTED_ROUTE,
            permission: 'fulfillments.picking',
            order: 26,
        };
    } else {
        tabs.pick = {
            id: 'pick',
            label: 'Pick',
            title: `${SHIP_TITLE_PREFIX} > Pick`,
            link: SHIP_PICK_ROUTE,
            permission: 'fulfillments.picking',
            order: 20,
        };
        tabs.groupPick = {
            id: 'groupPick',
            label: 'Group Pick',
            title: `${SHIP_TITLE_PREFIX} > Group Pick`,
            link: SHIP_GROUP_PICK_ROUTE,
            permission: 'fulfillments.picking',
            order: 30,
        };
        tabs.waves = {
            id: 'waves',
            label: 'Waves',
            link: () => firstAllowedLink(values(app.fulfillmentWavesTabs.getAll())),
            permission: () => combinePermissions(values(app.fulfillmentWavesTabs.getAll())),
            order: 40,
            children: values(app.fulfillmentWavesTabs.getAll()),
        };
        tabs.pack = {
            id: 'pack',
            label: 'Pack',
            title: `${SHIP_TITLE_PREFIX} > Pack`,
            link: SHIP_PACK_ROUTE,
            permission: 'fulfillments.packing',
            order: 50,
        };
    }

    return tabs;
};
