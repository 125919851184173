import {
    CLASS_CODES_TITLE_PREFIX,
    CLASS_CODES_INDEX_ROUTE,
    MAPPER_TITLE_PREFIX,
    MAPPER_INDEX_ROUTE,
    CARRIERS_TITLE_PREFIX,
    CARRIERS_INDEX_ROUTE,
    PARCELS_TITLE_PREFIX,
    PARCELS_INDEX_ROUTE,
} from './consts';

export default () => ({
    carriers: {
        id: 'Carriers & Services',
        label: CARRIERS_TITLE_PREFIX,
        link: CARRIERS_INDEX_ROUTE,
        permission: 'shipping - carriers.index',
        order: 10,
    },
    classCodes: {
        id: 'Class Codes',
        label: CLASS_CODES_TITLE_PREFIX,
        link: CLASS_CODES_INDEX_ROUTE,
        permission: 'shipping - class codes.index',
        order: 20,
    },
    optionMappers: {
        id: 'Shipping Option Mappers',
        label: MAPPER_TITLE_PREFIX,
        link: MAPPER_INDEX_ROUTE,
        permission: 'shipping - option mappers.index',
        order: 30,
    },
    parcels: {
        id: 'Parcels',
        label: PARCELS_TITLE_PREFIX,
        link: PARCELS_INDEX_ROUTE,
        permission: 'shipping - packages.index',
        order: 40,
    },
});
