import { useState } from 'react';

const useRemoveButton = ({ onUnselect = () => {} }) => {
    const [isConfirmationVisible, setConfirmationVisible] = useState(false);

    const handleRemove = () => {
        setConfirmationVisible(true);
    };
    const handleConfirmRemove = () => {
        onUnselect();
    };
    const handleCancleRemove = () => {
        setConfirmationVisible(false);
    };

    return [{ isConfirmationVisible }, { handleRemove, handleConfirmRemove, handleCancleRemove }];
};

export default useRemoveButton;
