import { constants, gql } from '@skunexus/utils';

const {
    CONFIG: { API_PATH },
} = constants;

export function fetchLineItems(id) {
    return gql.getGraphQLDetails({
        url: `${API_PATH}/query`,
        config: {
            domain: 'purchaseOrder',
        },
        fields: `
            lineItems(
                filter: {customValues: {is_cancelled: {operator: nin, value: 1}}}, 
                limit: {size: 9999, page: 1}
            ){
                rows {
                    id
                    product{
                        id
                        name
                        sku
                    }
                    replacementProducts {
                        product_id
                        product_sku
                        product_label
                        vendors {
                            value: id
                            label
                        }
                    }
                    product_id
                    price
                    relatedVendorProduct{
                        id
                    }
                    quantity
                }
            }
        `,
        payload: { id },
    });
}

export function fetchProducts(ids) {
    return gql.getGraphQLGrid({
        url: `${API_PATH}/query`,
        config: {
            domain: 'product',
        },
        gridQueryPart: `grid (
            sort: {name: ASC},
            filter: {id: {operator: in, value: ["${ids.join('","')}"]}}
        ) { 
            rows { 
              id
              customValues {
                custom_field_id
                value
              }
          }
        }`,
    });
}
