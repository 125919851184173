import RemedDecidableItems from './components/DecidableItems';
import RemedDecidedItems from './components/DecidedItems';

export default {
    name: 'customOrderDetails',
    before: [],
    after: [],
    setup: (app) => {
        const routeName = 'orderDetails';
        const route = app.routes.getItem(routeName);

        if (route) {
            const routeProps = route.props;
            route.props = (state) => ({
                ...routeProps(state),
                customGraphqlFields: {
                    items: `
                        parentRelatedProduct{
                            id
                            label
                            sku
                            name
                            weight
                        }
                        customValues{
                            custom_field_id
                            value
                        }
                    `,
                },
                decidedItemsComponent: RemedDecidedItems,
                decidableItemsComponent: RemedDecidableItems,
            });
            app.routes.changeItem(routeName, route);
        }
    },
};
