/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sumBy } from 'lodash';
import { ListingItems, ProductTag } from '@skunexus/component-catalog';

import styles from '../../styles.module.css';

export const hasAvailableOptions = (options = []) => !!options.filter(({ qty = 0 }) => qty > 0).length;

function Item(props) {
    const { item, routes, childrenItems = [] } = props;
    const { productDetailsPageRoute } = routes;

    const { product = {}, image = {}, totalItemQty = 0 } = item;

    const getTotalPrice = (items) =>
        sumBy(items, ({ decisions = [] }) => sumBy(decisions, ({ qty, price = null }) => qty * price)).toFixed(2);

    return (
        <ListingItems.Row className={classNames(styles.itemRow, styles.parent)}>
            <ListingItems.Cell className={styles.productData}>
                <ProductTag {...product} imageSrc={image?.url} route={productDetailsPageRoute} />
            </ListingItems.Cell>
            <ListingItems.Cell className={styles.qtyData} label="Qty">
                <div className="text-center">{totalItemQty}</div>
            </ListingItems.Cell>
            <ListingItems.Cell className={styles.spltQtyData} />
            <ListingItems.Cell className={styles.method} />
            <ListingItems.Cell className={styles.vendorPrice} />
            <ListingItems.Cell className={styles.itemPrice} />
            <ListingItems.Cell className={styles.totalPrice} label="Total Price">
                {getTotalPrice(childrenItems)}
            </ListingItems.Cell>
        </ListingItems.Row>
    );
}

Item.propTypes = {
    item: PropTypes.shape({
        itemId: PropTypes.string.isRequired,
        product: PropTypes.object,
        image: PropTypes.object,
        totalItemQty: PropTypes.number,
        customValues: PropTypes.array,
        decisions: PropTypes.array,
        shippingOptions: PropTypes.array,
        inStoreOptions: PropTypes.array,
        dropShipOptions: PropTypes.array,
    }),
    routes: PropTypes.shape({
        productDetailsPageRoute: PropTypes.string,
    }),
    childrenItems: PropTypes.array,
};

Item.defaultProps = {
    item: {},
    routes: {},
    childrenItems: [],
};

export default Item;
