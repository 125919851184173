import React from 'react';
import { ListingItems } from '@skunexus/component-catalog';

import styles from './styles.module.css';

function ItemsHeader() {
    return (
        <ListingItems.HeaderRow className={styles.itemRow}>
            <ListingItems.HeaderCell className={styles.productData}>Product</ListingItems.HeaderCell>
            <ListingItems.HeaderCell className={styles.qtyData}>Qty</ListingItems.HeaderCell>
            <ListingItems.HeaderCell className={styles.spltQtyData}>Split Qty</ListingItems.HeaderCell>
            <ListingItems.HeaderCell className={styles.method}>Select Fulfillment Method</ListingItems.HeaderCell>
            <ListingItems.HeaderCell className={styles.vendorPrice}>Vendor Price</ListingItems.HeaderCell>
            <ListingItems.HeaderCell className={styles.itemPrice}>Item Price</ListingItems.HeaderCell>
            <ListingItems.HeaderCell className={styles.totalPrice}>Total Price</ListingItems.HeaderCell>
        </ListingItems.HeaderRow>
    );
}

export default ItemsHeader;
