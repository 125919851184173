import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'reactstrap';
import { Button, FormItem } from '@skunexus/component-catalog';
import useProducts from './useProducts';
import AddProductsModal from './components/AddProductsModal';
import AddedProducts from './components/AddedProducts';

function Products(props) {
    const [{ products = [] }, { handleOpenModal, getModalProps, getProductItemsProps }] = useProducts(props);

    return (
        <>
            <Row>
                <Col>
                    <Button size="sm" outline icon="PlusCircle" onClick={handleOpenModal}>
                        Select products
                    </Button>
                    <AddProductsModal {...getModalProps()} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AddedProducts {...getProductItemsProps()} />

                    <FormItem
                        name="line_items"
                        showLabel={false}
                        type="hidden"
                        value={products.join(',')}
                        validate={{
                            required: { value: true, errorMessage: 'At least one product selection is required' },
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

Products.propTypes = {};

Products.defaultProps = {};

export default Products;
