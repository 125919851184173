import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { createRoutes } from '../createRoutes';
import app from '../config';
import logout from '../routes/logout';
import { filterAccessibleItems, keyedRoutes } from '../utils/helpers';
import MainLayout from './MainLayout';

export default function () {
    const accessibleRoutes = filterAccessibleItems(keyedRoutes(app.routes.getAll()));

    return (
        <Switch>
            {createRoutes(accessibleRoutes, MainLayout)}
            {logout()}
            <Redirect path="*" to="/dashboard" />
        </Switch>
    );
}
