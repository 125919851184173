import React from 'react';
import PropTypes from 'prop-types';

import { AttributesFormFields } from '@skunexus/custom-fields';
import { useCustomAttributes } from './useCustomAttributes';

function CustomAttributes(props) {
    const { formData } = props;
    const { customFields, getMappedCustomFields, handleCustomFieldChange } = useCustomAttributes(props);

    return (
        <AttributesFormFields
            isCreateMode
            hideDisabledMode
            customFields={customFields}
            formData={formData}
            onFieldsChange={handleCustomFieldChange}
            sectionsConfig={[
                {
                    code: 'customerInfo',
                    label: 'Customer Info',
                    order: 10,
                    fields: getMappedCustomFields([
                        'customer_first_name',
                        'customer_last_name',
                        'customer_name',
                        'customer_email',
                        'customer_phone',
                        'customer_fully_qualified_name',
                    ]),
                    keepFieldsOrder: true,
                },
                {
                    code: 'orderInfo',
                    label: 'Order Info',
                    order: 20,
                    fields: getMappedCustomFields([
                        'currency_code',
                        'dispatch_date',
                        'gift_message',
                        'payment_method',
                        'pickup_date',
                        'shipping_method',
                        'tags',
                    ]),
                },
                {
                    code: 'costInfo',
                    label: 'Cost Info',
                    order: 30,
                    fields: getMappedCustomFields([
                        'total_items_price',
                        'discount_amount',
                        'tax_price',
                        'shipping_amount',
                        'overall_total',
                    ]),
                    keepFieldsOrder: true,
                },
            ]}
        />
    );
}

CustomAttributes.propTypes = {
    formData: PropTypes.object,
};

CustomAttributes.defaultProps = {
    formData: {},
};

export default CustomAttributes;
