import { constants, gql } from '@skunexus/utils';

const {
    CONFIG: { API_PATH },
} = constants;

export function fetchProducts(ids) {
    return gql.getGraphQLGrid({
        url: `${API_PATH}/query`,
        config: {
            domain: 'product',
        },
        gridQueryPart: `grid (
          sort: {name: ASC},
            filter: {id: {operator: in, value: ["${ids.join('","')}"]}}
        ) { 
            rows { 
              id
              customValues {
                custom_field_id
                value
              }
          }
        }`,
    });
}
