import { connect } from 'react-redux';
import { actions as customFieldsActions } from '@skunexus/custom-fields';
import Component from './CustomAttributes';

const mapStateToProps = (state, { domain }) => ({
    customFields: state.customFields.data[domain],
});

const mapDispatchToProps = {
    fetchCustomFields: customFieldsActions.customFields.fetchCustomFields.TRIGGER,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
