import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'reactstrap';
import { Card } from '@skunexus/component-catalog';
import Grid from '@skunexus/grid';

import useDecidedItems from './useDecidedItems';

function DecidedItems(props) {
    const { isLoading } = props;
    const [{ title, itemsCount }, { getGridProps }] = useDecidedItems(props);

    if (!itemsCount) return null;

    return (
        <Row className="mt-3">
            <Col>
                <Card title={title} isLoading={isLoading} noPadding>
                    <Grid {...getGridProps()} />
                </Card>
            </Col>
        </Row>
    );
}

DecidedItems.propTypes = {
    isLoading: PropTypes.bool,
};

DecidedItems.defaultProps = {
    isLoading: false,
};

export default DecidedItems;
