/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { AvForm } from 'availity-reactstrap-validation';
import { Button, ProductTag, ListingItems, Modal, SmstIcon } from '@skunexus/component-catalog';

import Input from './components/Input';
import RemoveButton from './components/RemoveButton';
import useAddProductsModal from './useAddProductsModal';

import styles from './styles.module.css';

function AddProductModal(props) {
    const { isOpen, isLoading, searchPlaceholder, searchMessage, errorNotification } = props;
    const [
        { inputRef, searchText, formRef, items },
        {
            handleCloseModal,
            handleSearch,
            handleChangeItemQty,
            handleSelectItem,
            handleUnselectItem,
            handleConfirm,
            handleSubmit,
        },
    ] = useAddProductsModal(props);

    return (
        <Modal
            isOpen={isOpen}
            isLoading={isLoading}
            headerLabel="Add Products"
            cancelOnClick={handleCloseModal}
            confirmOnClick={handleConfirm}
            footerProps={{
                confirmProps: {
                    disabled: !items.length,
                },
            }}
            cancelLabel="Close"
            size="lg"
            className={styles.modal}
        >
            <div className="mb-3">
                <input
                    ref={inputRef}
                    id="search"
                    type="text"
                    name="search"
                    onChange={(event) => handleSearch(event.target.value)}
                    className="form-control"
                    placeholder={searchPlaceholder}
                />
                {!items.length && <small className="form-text text-muted">{searchMessage}</small>}
            </div>
            {!items.length && !isLoading && searchText !== '' && <p className="no-data">No results found.</p>}
            {!!items.length && (
                <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                    <div className={styles.itemsWrapper}>
                        <ListingItems.Row className={styles.itemRow}>
                            <ListingItems.HeaderCell className={styles.productData}>Product</ListingItems.HeaderCell>
                            <ListingItems.HeaderCell className={styles.qtyData}>Qty</ListingItems.HeaderCell>
                            <ListingItems.HeaderCell className={styles.priceData}>Price</ListingItems.HeaderCell>
                        </ListingItems.Row>
                        {items.map(
                            (
                                { isSelected = false, isAdded = false, name, sku, qty = 1, qty_on_hand, price, id },
                                index,
                            ) => (
                                <ListingItems.Row
                                    key={id}
                                    className={classnames(styles.itemRow, {
                                        [styles.added]: isSelected || isAdded,
                                        [styles.disabled]: !qty_on_hand,
                                    })}
                                >
                                    <ListingItems.Cell className={styles.productData}>
                                        <div className={styles.actions}>
                                            {!!qty_on_hand && !isSelected && !isAdded && (
                                                <Button
                                                    onlyIcon
                                                    icon="PlusLg"
                                                    color="link"
                                                    size="lg"
                                                    onClick={() => handleSelectItem(id)}
                                                />
                                            )}
                                            {isAdded && <SmstIcon className={styles.checkIcon} icon="CheckSquare" />}
                                            {isSelected && <RemoveButton onUnselect={() => handleUnselectItem(id)} />}
                                        </div>
                                        <ProductTag name={name} sku={sku} />
                                    </ListingItems.Cell>
                                    <ListingItems.Cell className={styles.qtyData}>
                                        {isSelected ? (
                                            <div>
                                                <Input
                                                    min={1}
                                                    max={qty_on_hand}
                                                    step={1}
                                                    value={qty}
                                                    type="number"
                                                    name={`items[${index}].qty`}
                                                    // eslint-disable-next-line no-shadow
                                                    onChange={(qty) => handleChangeItemQty({ id, qty })}
                                                    errorNotification={errorNotification}
                                                />{' '}
                                                of {qty_on_hand}
                                            </div>
                                        ) : (
                                            qty_on_hand
                                        )}
                                    </ListingItems.Cell>
                                    <ListingItems.Cell className={styles.priceData}>{price}</ListingItems.Cell>
                                </ListingItems.Row>
                            ),
                        )}
                    </div>
                </AvForm>
            )}
        </Modal>
    );
}

AddProductModal.propTypes = {
    isOpen: PropTypes.array,
    isLoading: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    searchMessage: PropTypes.string,
    errorNotification: PropTypes.func.isRequired,
};

AddProductModal.defaultProps = {
    isOpen: false,
    isLoading: false,
    searchPlaceholder: 'Search Product by Name',
    searchMessage: 'To add a product, search by product name and then click the plus icon next to each product.',
};

export default AddProductModal;
