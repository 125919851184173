/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, ListingItems, ProductTag, SmstIcon, Spinner } from '@skunexus/component-catalog';
import { sumBy } from 'lodash';
import QtyInput from '../AddProductsModal/components/Input';
import PriceInput from './components/PriceInput';
import useAddedProducts from './useAddedProducts';

import styles from './styles.module.css';

function AddedProducts(props) {
    const { isLoading = false, errorNotification = () => {} } = props;
    const [
        { items = [] },
        { handleChangeItemQty, handleChangeItemPrice, handleChangeChildrenPrice, handleRemoveItem },
    ] = useAddedProducts(props);

    return (
        <>
            {isLoading && <Spinner className={styles.spinner} />}
            {!items.length && <p className="mb-0 no-data">No Products Selected</p>}
            {!!items.length && (
                <div className={styles.itemsWrapper}>
                    <ListingItems.Row className={styles.itemRow}>
                        <ListingItems.HeaderCell className={styles.productData}>Product</ListingItems.HeaderCell>
                        <ListingItems.HeaderCell className={styles.qtyData}>Ordered</ListingItems.HeaderCell>
                        <ListingItems.HeaderCell className={styles.priceData}>Price</ListingItems.HeaderCell>
                        <ListingItems.HeaderCell className={styles.totalPriceData}>Total Price</ListingItems.HeaderCell>
                        <ListingItems.HeaderCell className={styles.actions}>Actions</ListingItems.HeaderCell>
                    </ListingItems.Row>
                    {items.map(({ name, sku, qty = 1, qty_on_hand, is_kit, price, id, children = [] }, index) => (
                        <>
                            <ListingItems.Row
                                className={classNames(styles.itemRow, { [styles.parent]: children.length })}
                            >
                                <ListingItems.Cell className={styles.productData}>
                                    <ProductTag name={name} sku={sku} />
                                </ListingItems.Cell>
                                <ListingItems.Cell className={styles.qtyData}>
                                    <QtyInput
                                        min={1}
                                        max={qty_on_hand}
                                        step={1}
                                        value={qty}
                                        type="number"
                                        name={`items[${index}].qty`}
                                        // eslint-disable-next-line no-shadow
                                        onChange={(qty) => handleChangeItemQty({ id, qty })}
                                        errorNotification={errorNotification}
                                    />{' '}
                                    of {qty_on_hand}
                                </ListingItems.Cell>
                                <ListingItems.Cell className={styles.priceData}>
                                    {!is_kit && (
                                        <PriceInput
                                            min={0}
                                            value={price}
                                            type="number"
                                            name={`items[${index}].price`}
                                            // eslint-disable-next-line no-shadow
                                            onChange={(price) => handleChangeItemPrice({ id, price })}
                                            errorNotification={errorNotification}
                                        />
                                    )}
                                </ListingItems.Cell>
                                <ListingItems.Cell className={styles.totalPriceData}>
                                    {is_kit
                                        ? parseFloat(
                                              sumBy(
                                                  children,
                                                  ({ qty: childQty = 1, price: childPrice }) =>
                                                      qty * childQty * childPrice,
                                              ),
                                          ).toFixed(2)
                                        : parseFloat(qty * price).toFixed(2)}
                                </ListingItems.Cell>
                                <ListingItems.Cell className={styles.actions}>
                                    <Button onlyIcon icon="Trash3" outline onClick={() => handleRemoveItem(id)} />
                                </ListingItems.Cell>
                            </ListingItems.Row>
                            {children.map(
                                (
                                    {
                                        id: childId,
                                        name: childName,
                                        sku: childSku,
                                        price: childPrice,
                                        qty: childQty = 1,
                                    },
                                    subIndex,
                                ) => (
                                    <ListingItems.Row className={classNames(styles.itemRow, styles.child)}>
                                        <ListingItems.Cell className={styles.productData}>
                                            <span className="float-left mr-2 d-block">
                                                <SmstIcon icon="ArrowReturnRight" />
                                            </span>
                                            <ProductTag name={childName} sku={childSku} />
                                        </ListingItems.Cell>
                                        <ListingItems.Cell className={styles.qtyData}>
                                            {qty * childQty}
                                        </ListingItems.Cell>
                                        <ListingItems.Cell className={styles.priceData}>
                                            <PriceInput
                                                min={0}
                                                value={childPrice}
                                                type="number"
                                                name={`items[${index}].children.[${subIndex}].price`}
                                                // eslint-disable-next-line no-shadow
                                                onChange={(price) => handleChangeChildrenPrice({ id, childId, price })}
                                                errorNotification={errorNotification}
                                            />
                                        </ListingItems.Cell>
                                        <ListingItems.Cell className={styles.totalPriceData}>
                                            {parseFloat(qty * childQty * childPrice).toFixed(2)}
                                        </ListingItems.Cell>
                                        <ListingItems.Cell className={styles.actions} />
                                    </ListingItems.Row>
                                ),
                            )}
                        </>
                    ))}
                </div>
            )}
        </>
    );
}

AddedProducts.propTypes = {
    isLoading: PropTypes.bool,
    errorNotification: PropTypes.func,
};

AddedProducts.defaultProps = {
    isLoading: false,
    errorNotification: () => {},
};

export default AddedProducts;
