import React from 'react';
import PropTypes from 'prop-types';
import { AvForm } from 'availity-reactstrap-validation';
import { Row, Col } from 'reactstrap';
import { FormItem, Modal } from '@skunexus/component-catalog';
import useClientField from './useClientField';

function ClientField(props) {
    const { value: customerId, isLoading } = props;
    const [{ formRef, isOpen, isSubmitting, email, customerOptions }, { handleChange, handleSubmit, handleConfirm }] =
        useClientField(props);

    return (
        <>
            <FormItem
                name="customer_id"
                label="Customer"
                type="dropdown"
                value={customerId}
                options={customerOptions}
                onChange={handleChange}
                validate={{
                    required: { value: true },
                }}
                selectProps={{
                    isDisabled: isLoading,
                }}
            />
            <Modal
                headerLabel="Confirm Customer Email"
                isOpen={isOpen}
                isLoading={isSubmitting}
                confirmOnClick={handleConfirm}
            >
                <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                    <Row>
                        <Col xs="12">
                            <FormItem
                                name="email"
                                label="Email"
                                className="w-100"
                                value={email}
                                validate={{
                                    email: {
                                        value: true,
                                        errorMessage: 'Email is invalid',
                                    },
                                    required: {
                                        value: true,
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                </AvForm>
            </Modal>
        </>
    );
}

ClientField.propTypes = {
    value: PropTypes.string,
    isLoading: PropTypes.bool,
};

ClientField.defaultProps = {
    value: '',
    isLoading: false,
};
export default ClientField;
