/* eslint-disable camelcase */
import { useState, useRef, useEffect } from 'react';
import { get, find, isNumber } from 'lodash';

const useForm = ({ products = [], onConfirm }) => {
    const formRef = useRef(null);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setItems(
            products.map(({ customValues = [], relatedProduct = [], ...item }) => ({
                ...item,
                isSelected: false,
                price: get(find(customValues, ['custom_field_id', 'price']), 'value', 0),
                children: relatedProduct,
            })),
        );
    }, [products]);

    const handleChangeItemQty = ({ id, qty }) => {
        setItems((state) => [
            // eslint-disable-next-line no-shadow
            ...state.map((item) => {
                const { id: itemId } = item;

                if (itemId === id) {
                    return {
                        ...item,
                        qty,
                    };
                }

                return item;
            }),
        ]);
    };

    const handleSelectItem = (id) => {
        setItems((state) => [
            ...state.map((item) => {
                const { id: itemId } = item;

                if (itemId === id) {
                    return {
                        ...item,
                        isSelected: true,
                        qty: 1,
                    };
                }

                return item;
            }),
        ]);
    };

    const handleUnselectItem = (id) => {
        setItems((state) => [
            ...state.map((item) => {
                const { id: itemId } = item;

                if (itemId === id) {
                    return {
                        ...item,
                        isSelected: false,
                        qty: 0,
                    };
                }

                return item;
            }),
        ]);
    };

    const handleSubmit = () => {
        onConfirm(items.filter(({ isSelected, qty }) => isSelected && isNumber(qty) && qty > 0));
    };

    return [
        {
            formRef,
            items,
        },
        {
            handleChangeItemQty,
            handleSelectItem,
            handleUnselectItem,
            handleSubmit,
        },
    ];
};

export default useForm;
