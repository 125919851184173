/* eslint-disable camelcase */
import { useEffect } from 'react';
import { get, find, set, reduce, sumBy } from 'lodash';

const TOTAL_ITEMS_PRICE = 'total_items_price';
const DISCOUNT_AMOUNT = 'discount_amount';
const SHIPPING_AMOUNT = 'shipping_amount';
const TAX_PRICE = 'tax_price';
const OVERALL_TOTAL = 'overall_total';
export const OVERALL_TOTAL_PARTS = [TOTAL_ITEMS_PRICE, DISCOUNT_AMOUNT, SHIPPING_AMOUNT, TAX_PRICE];

const useCustomAttributes = ({ dictionaries, formData, setFormData }) => {
    const overAllTotalParts = [
        get(formData.customValues, TOTAL_ITEMS_PRICE),
        get(formData.customValues, DISCOUNT_AMOUNT),
        get(formData.customValues, SHIPPING_AMOUNT),
        get(formData.customValues, TAX_PRICE),
    ];

    useEffect(() => {
        setFormData((state) => {
            const totalItemsPrice = parseFloat(get(state.customValues, TOTAL_ITEMS_PRICE, 0) || 0);
            const discountAmount = parseFloat(get(state.customValues, DISCOUNT_AMOUNT, 0) || 0);
            const shippingAmount = parseFloat(get(state.customValues, SHIPPING_AMOUNT, 0) || 0);
            const taxPrice = parseFloat(get(state.customValues, TAX_PRICE, 0) || 0);

            const overallTotal = totalItemsPrice - discountAmount + taxPrice + shippingAmount;

            set(state, `customValues.${OVERALL_TOTAL}`, parseFloat(overallTotal || 0).toFixed(2));
            return { ...state };
        });
    }, overAllTotalParts);

    useEffect(() => {
        if (formData.customer_id) {
            const customers = get(dictionaries, 'customers', []);
            const customer = find(customers, ['value', formData.customer_id]);

            const customerCustomValues = get(customer, 'customValues', []);
            const customerFullyQualifiedName = get(
                find(customerCustomValues, ['custom_field_id', 'fully_qualified_name']),
                'value',
                '',
            );

            setFormData((state) => {
                const firstName = get(customer, 'first_name', '');
                const lastName = get(customer, 'last_name', '');
                const email = get(customer, 'email', '');
                const fullName = `${firstName} ${lastName}`.trim();

                set(state, 'customValues.customer_first_name', firstName);
                set(state, 'customValues.customer_last_name', lastName);
                set(state, 'customValues.customer_name', fullName);
                set(state, 'customValues.customer_email', email);
                set(state, 'customValues.customer_fully_qualified_name', customerFullyQualifiedName);

                return { ...state };
            });
        }
    }, [formData.customer_id]);

    useEffect(() => {
        setFormData((state) => {
            const firstName = get(state.shipping_address, 'first_name', '');
            const lastName = get(state.shipping_address, 'last_name', '');
            const fullName = `${firstName} ${lastName}`.trim();
            const telephone = get(state.shipping_address, 'telephone', '');
            const email = get(state.shipping_address, 'email', '');

            if (firstName) {
                set(state, 'customValues.customer_first_name', firstName);
            }
            if (lastName) {
                set(state, 'customValues.customer_last_name', lastName);
            }
            if (fullName) {
                set(state, 'customValues.customer_name', fullName);
            }
            if (telephone) {
                set(state, 'customValues.customer_phone', telephone);
            }
            if (email) {
                set(state, 'customValues.customer_email', email);
            }
            return { ...state };
        });
    }, [formData.shipping_address]);

    useEffect(() => {
        setFormData((state) => {
            const price = reduce(
                state.line_items,
                // eslint-disable-next-line no-shadow
                (result, { price, qty, is_kit, children = [] }) => {
                    if (is_kit) {
                        return (
                            result +
                            sumBy(children, ({ qty: childQty, price: childPrice }) => qty * childQty * childPrice)
                        );
                    }
                    return result + qty * price;
                },
                0,
            );

            set(state, `customValues.${TOTAL_ITEMS_PRICE}`, parseFloat(price).toFixed(2));
            return { ...state };
        });
    }, [JSON.stringify(formData.line_items)]);

    return {};
};

export default useCustomAttributes;
