import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { Button, ListingItems, Modal } from '@skunexus/component-catalog';

import useResolvePurchaseOrder from './useResolvePurchaseOrder';
import Item from './components/Item';

import styles from './styles.module.css';

function ResolvePurchaseOrder(props) {
    const { errorNotification } = props;
    const [
        { formRef, isOpen, isLoading, isSubmitting, isDisabled, items },
        {
            handleOpenModal,
            handleCloseModal,
            handleCheck,
            handleSplit,
            handleRemoveSubItem,
            handlePriceChange,
            handleQtyChange,
            handleVendorChange,
            handleVendorPriceChange,
            handleProductChange,
            handleConfirm,
            handleSubmit,
        },
    ] = useResolvePurchaseOrder(props);
    return (
        <Row>
            <Col>
                <Button size="md" onClick={handleOpenModal}>
                    Resolve Purchase Order
                </Button>
                <Modal
                    isOpen={isOpen}
                    isLoading={isLoading}
                    headerLabel="Resolve Purchase Order"
                    size="lg"
                    className={styles.modal}
                    confirmOnClick={handleConfirm}
                    confirmLabel="Resolve PO"
                    cancelOnClick={handleCloseModal}
                    cancelLabel="Close"
                    footerProps={{
                        confirmProps: {
                            isLoading: isSubmitting,
                            disabled: isDisabled,
                        },
                        cancelProps: {
                            disabled: isSubmitting,
                        },
                    }}
                >
                    {!items.length && <p className="no-data">No items</p>}
                    {!!items.length && (
                        <AvForm ref={formRef} onValidSubmit={handleSubmit} className={styles.form}>
                            <div className={styles.itemsWrapper}>
                                <ListingItems.Row className={styles.itemRow}>
                                    <ListingItems.HeaderCell className={styles.checkboxData} />
                                    <ListingItems.HeaderCell className={styles.productData}>
                                        Product
                                    </ListingItems.HeaderCell>
                                    <ListingItems.HeaderCell className={styles.qtyData}>Qty</ListingItems.HeaderCell>
                                    <ListingItems.HeaderCell className={styles.splitQtyData}>
                                        Split Qty
                                    </ListingItems.HeaderCell>
                                    <ListingItems.HeaderCell className={styles.priceData}>
                                        Product Price
                                    </ListingItems.HeaderCell>
                                    <ListingItems.HeaderCell className={styles.actions} />
                                </ListingItems.Row>
                                {items.map((item) => (
                                    <Item
                                        key={item.id}
                                        item={item}
                                        onCheck={handleCheck}
                                        onPriceChange={handlePriceChange}
                                        onQtyChange={handleQtyChange}
                                        onSplit={handleSplit}
                                        onRemoveSubItem={handleRemoveSubItem}
                                        onVendorChange={handleVendorChange}
                                        onVendorPriceChange={handleVendorPriceChange}
                                        onProductChange={handleProductChange}
                                        errorNotification={errorNotification}
                                    />
                                ))}
                            </div>
                        </AvForm>
                    )}
                </Modal>
            </Col>
        </Row>
    );
}

ResolvePurchaseOrder.propTypes = {
    errorNotification: PropTypes.func.isRequired,
};

ResolvePurchaseOrder.defaultProps = {};

export default ResolvePurchaseOrder;
