import { useReducer, useEffect } from 'react';

import itemsReducer, {
    INIT_ACTION,
    ADD_SPLIT_ACTION,
    REMOVE_SPLIT_ACTION,
    CHANGE_QTY_ACTION,
    CHANGE_METHOD_ACTION,
    CHANGE_METHOD_ALL_ACTION,
    CHANGE_LOCATION_ACTION,
    CHANGE_LOCATION_ALL_ACTION,
    CHANGE_PRICE_ACTION,
    CHANGE_VENDOR_PRICE_ACTION,
} from '../reducers/items';

const initialState = [];

const useItems = ({ items = [] }) => {
    const [state, dispatch] = useReducer(itemsReducer, initialState);

    useEffect(() => {
        dispatch({
            type: INIT_ACTION,
            payload: items,
        });
    }, [items]);

    const addSplit = (payload) => {
        dispatch({
            type: ADD_SPLIT_ACTION,
            payload,
        });
    };
    const removeSplit = (payload) => {
        dispatch({
            type: REMOVE_SPLIT_ACTION,
            payload,
        });
    };

    const changeQty = (payload) => {
        dispatch({
            type: CHANGE_QTY_ACTION,
            payload,
        });
    };

    const changeMethod = (payload) => {
        dispatch({
            type: CHANGE_METHOD_ACTION,
            payload,
        });
    };

    const changeMethodAll = (payload) => {
        dispatch({
            type: CHANGE_METHOD_ALL_ACTION,
            payload,
        });
    };

    const changeLocation = (payload) => {
        dispatch({
            type: CHANGE_LOCATION_ACTION,
            payload,
        });
    };

    const changeLocationAll = (payload) => {
        dispatch({
            type: CHANGE_LOCATION_ALL_ACTION,
            payload,
        });
    };

    const changePrice = (payload) => {
        dispatch({
            type: CHANGE_PRICE_ACTION,
            payload,
        });
    };

    const changeVendorPrice = (payload) => {
        dispatch({
            type: CHANGE_VENDOR_PRICE_ACTION,
            payload,
        });
    };

    return [
        { decidableItems: state },
        {
            addSplit,
            removeSplit,
            changeQty,
            changeMethod,
            changeMethodAll,
            changeLocation,
            changeLocationAll,
            changePrice,
            changeVendorPrice,
        },
    ];
};

export default useItems;
