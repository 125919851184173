import { useState } from 'react';

const useModal = () => {
    const [isOpen, setModalOpen] = useState(false);

    const closeModal = () => {
        setModalOpen(false);
    };

    const openModal = () => {
        setModalOpen(true);
    };

    return [{ isOpen }, { openModal, closeModal }];
};

export default useModal;
