import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ajaxExceptionMessage } from '@skunexus/utils';
import { get } from 'lodash';
import { Button, FormItem, Spinner } from '@skunexus/component-catalog';

import PriceInput from '../PriceInput';
import useCancellation from './hooks/useCancellation';
import useReplaceable from './hooks/useReplaceable';
import useVendorProduct from './hooks/useVendorProduct';

import { CANCEL_RESOLVE_OPTION } from '../../../../../../constants';

import styles from './styles.module.css';

function Actions({
    id,
    index,
    vendorId,
    vendorPrice,
    replacementProductId,
    replacementProducts,
    onVendorChange = () => {},
    onVendorPriceChange = () => {},
    onProductChange = () => {},
    errorNotification = () => {},
}) {
    const [{ isCancelOptionEnabled }] = useCancellation({ replacementProducts });
    const [{ productOptions, vendorOptions }] = useReplaceable({ replacementProductId, replacementProducts });
    const [{ error, isLoading }, { fetchVendorProduct }] = useVendorProduct();

    useEffect(() => {
        if (error) {
            errorNotification(ajaxExceptionMessage(error));
        }
    }, [error]);

    useEffect(() => {
        const fetchVendorPriceData = async () => {
            const vendorProduct = await fetchVendorProduct({ productId: replacementProductId, vendorId });
            if (vendorProduct && get(vendorProduct, 'price')) {
                onVendorPriceChange(get(vendorProduct, 'price'));
            }
        };
        if (vendorId) {
            fetchVendorPriceData();
        }
    }, [vendorId]);

    useEffect(() => {
        if (vendorOptions.length === 1) {
            onVendorChange(vendorOptions[0].value);
        }
    }, [replacementProductId, vendorOptions]);

    const isCancelOptionSelected = replacementProductId === CANCEL_RESOLVE_OPTION;

    return (
        <div className={styles.actionsWrapper}>
            {!!productOptions.length && (
                <div className={styles.replaceWrapper}>
                    <FormItem
                        label="Replacement Product"
                        type="dropdown"
                        name={`items[${id}][${index}].product_id`}
                        options={productOptions}
                        onChange={(value) => onProductChange(value)}
                        value={(isCancelOptionSelected ? '' : replacementProductId) || ''}
                        placeholder="Select"
                        selectProps={{
                            isClearable: true,
                            isDisabled: isCancelOptionSelected,
                        }}
                    />
                    {replacementProductId && !!vendorOptions.length && (
                        <div className={styles.vendorWrapper}>
                            <FormItem
                                label="Vendor"
                                type="dropdown"
                                name={`items[${id}][${index}].vendor_id`}
                                options={vendorOptions}
                                onChange={(value) => onVendorChange(value)}
                                value={vendorId || ''}
                                placeholder="Select"
                                validate={{ required: { value: true } }}
                            />
                            <div className={styles.vendorPriceWrapper}>
                                <PriceInput
                                    label="Vendor Price"
                                    type="number"
                                    name={`items[${id}][${index}].vendor_price`}
                                    onChange={(value) => onVendorPriceChange(value)}
                                    value={vendorPrice || ''}
                                    validate={{ required: { value: true } }}
                                    disabled={isLoading || !vendorId}
                                />
                                {isLoading && <Spinner size="sm" className={styles.vendorSpinner} />}
                            </div>
                        </div>
                    )}
                </div>
            )}
            {isCancelOptionEnabled && (
                <div className={styles.cancellationWrapper}>
                    <Button
                        size="sm"
                        color="danger"
                        outline={!isCancelOptionSelected}
                        onClick={() => onProductChange(!isCancelOptionSelected ? CANCEL_RESOLVE_OPTION : null)}
                    >
                        Cancel item
                    </Button>
                </div>
            )}
        </div>
    );
}

Actions.propTypes = {
    id: PropTypes.string,
    index: PropTypes.number,
    vendorId: PropTypes.string,
    vendorPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    replacementProductId: PropTypes.string,
    replacementProducts: PropTypes.array,
    onVendorChange: PropTypes.func,
    onVendorPriceChange: PropTypes.func,
    onProductChange: PropTypes.func,
    errorNotification: PropTypes.func,
};

Actions.defaultProps = {
    id: '',
    index: 0,
    vendorId: '',
    vendorPrice: '',
    replacementProductId: '',
    replacementProducts: [],
    onVendorChange: () => {},
    onVendorPriceChange: () => {},
    onProductChange: () => {},
    errorNotification: () => {},
};

export default Actions;
/* eslint-enable */
