/* eslint-disable camelcase */
import { get, find } from 'lodash';

import { CANCEL_RESOLVE_OPTION } from '../../../../../../../constants';

const useReplaceable = ({ replacementProductId, replacementProducts = [] }) => {
    const getVendorOptions = (_replacementProductId) =>
        (get(find(replacementProducts, ['product_id', _replacementProductId]), 'vendors', []) || []).sort(
            ({ label: labelA }, { label: labelB }) => {
                if (labelA < labelB) return -1;
                if (labelA > labelB) return 1;

                return 0;
            },
        );

    const productOptions = replacementProducts
        .map(({ product_id, product_sku, product_label }) => ({
            value: product_id,
            label: `${product_label}${product_label !== product_sku ? ` (${product_sku})` : ''}`,
        }))
        .filter(({ value }) => value !== CANCEL_RESOLVE_OPTION)
        .sort(({ label: labelA }, { label: labelB }) => {
            if (labelA < labelB) return -1;
            if (labelA > labelB) return 1;

            return 0;
        });

    return [{ productOptions, vendorOptions: getVendorOptions(replacementProductId) }, {}];
};

export default useReplaceable;
