import { get, find } from 'lodash';

export default {
    name: 'customCustomerDetails',
    before: [],
    after: [],
    setup: (app) => {
        const routeName = 'customerDetails';
        const route = app.routes.getItem(routeName);

        if (route) {
            const routeProps = route.props;

            const customDetailsSecondColumnAttributes = (data) =>
                data.filter(({ key }) => !['orders_count'].includes(key));

            const customOrdersGridData = (data) => {
                const customerFullyQualifiedNameCol = {
                    field: 'customValues.customer_fully_qualified_name',
                    label: 'Customer Qualified Name',
                    type: 'string',
                };
                const columns = [...data.columns];
                columns.splice(2, 0, customerFullyQualifiedNameCol);

                return {
                    ...data,
                    columns,
                };
            };

            const customOrdersGridConfig = (data, customer = {}) => {
                const customerCustomValues = get(customer, 'customValues', []);
                const customerFullyQualifiedName = get(
                    find(customerCustomValues, ['custom_field_id', 'fully_qualified_name']),
                    'value',
                    '',
                );

                if (!customerFullyQualifiedName) {
                    return data;
                }

                return {
                    ...data,
                    filtering: {
                        customer_fully_qualified_name: {
                            filterType: 'equal',
                            value: customerFullyQualifiedName,
                        },
                    },
                };
            };

            const customOrdersCardProps = () => ({ title: 'Customer Orders' });

            route.props = (state) => ({
                ...routeProps(state),
                customOrdersGridConfig,
                customOrdersGridData,
                customOrdersCardProps,
                customDetailsSecondColumnAttributes,
            });
            app.routes.changeItem(routeName, route);
        }
    },
};
