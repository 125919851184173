/* eslint-disable camelcase */
import { get, flatten } from 'lodash';
import { helpers } from '@skunexus/custom-fields';
import RemedProducts from './components/Products';
import RemedClientField from './components/ClientField';
import RemedCustomAttributes from './components/CustomAttributes';
import customAttributesHook from './hooks/useCustomAttributes';

export default {
    name: 'customOrderForm',
    before: [],
    after: [],
    setup: (app) => {
        const routeName = 'orderForm';
        const route = app.routes.getItem(routeName);

        const customFormPayload = (data, formData) => ({
            ...data,
            items: flatten(
                get(formData, 'line_items', []).map(({ id, qty, price, is_kit, children = [] }, index) => {
                    if (is_kit) {
                        return children.map(({ id: childId, price: childPrice, qty: childQty }) => ({
                            product_id: childId,
                            qty: childQty * qty,
                            custom_values: [
                                {
                                    custom_field_id: 'item_price',
                                    value: childPrice,
                                },
                                {
                                    custom_field_id: 'parent_external_item_id',
                                    value: index + 1,
                                },
                                {
                                    custom_field_id: 'parent_product_id',
                                    value: id,
                                },
                                {
                                    custom_field_id: 'parent_qty',
                                    value: qty,
                                },
                            ],
                        }));
                    }

                    return [
                        {
                            product_id: id,
                            qty,
                            custom_values: [
                                {
                                    custom_field_id: 'item_price',
                                    value: price,
                                },
                                {
                                    custom_field_id: 'parent_external_item_id',
                                    value: index + 1,
                                },
                                {
                                    custom_field_id: 'parent_product_id',
                                    value: id,
                                },
                                {
                                    custom_field_id: 'parent_qty',
                                    value: qty,
                                },
                            ],
                        },
                    ];
                }),
            ),
        });

        if (route) {
            const routeProps = route.props;
            route.props = (state) => ({
                ...routeProps(state),
                clientFieldComponent: RemedClientField,
                productsComponent: RemedProducts,
                customAttributesComponent: RemedCustomAttributes,
                customFormPayload,
                customAttributesHook,
                customGraphqlFields: {
                    customers: helpers.apiHelper.customValuesField,
                },
            });
            app.routes.changeItem(routeName, route);
        }
    },
};
