/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, find, reduce } from 'lodash';
import { FormElements, ListingItems, ProductTag, Button, Select, SmstIcon } from '@skunexus/component-catalog';
import { constants } from '@skunexus/order-details';

import VendorPrice from './components/VendorPrice';

import styles from '../../styles.module.css';

const { SHIPMENT_DELIVERY_METHOD, PICKUP_DELIVERY_METHOD, DROPSHIP_DELIVERY_METHOD, CANCEL_METHOD } = constants;

export const hasAvailableOptions = (options = []) => !!options.filter(({ qty = 0 }) => qty > 0).length;

function Item(props) {
    const {
        item,
        routes,
        addSplit = () => {},
        removeSplit = () => {},
        changeQty = () => {},
        changeMethod = () => {},
        changeLocation = () => {},
        changeVendorPrice = () => {},
        errorNotification = () => {},
    } = props;
    const { productDetailsPageRoute } = routes;

    const {
        parentId = null,
        itemId,
        product = {},
        image = {},
        totalItemQty = 0,
        decisions = [],
        shippingOptions = [],
        inStoreOptions = [],
        dropShipOptions = [],
    } = item;

    const isSplitted = decisions.length > 1;
    const isMaxed = decisions.length >= parseInt(totalItemQty, 10);
    const freeQtyToSplit = totalItemQty - reduce(decisions, (result, { qty }) => result + (qty || 0), 0);

    const isSplitable = shippingOptions.length > 0 || inStoreOptions.length > 0 || dropShipOptions.length > 0;

    // eslint-disable-next-line no-shadow
    const handleChange = (e, { max, itemId, index }) => {
        const newQty = parseInt(get(e, 'target.value', 0), 10);
        if (newQty < 0) {
            errorNotification('Split QTY cannot be less than 0');
            return;
        }
        if (newQty > max) {
            errorNotification('Cannot assign more than was ordered');
            return;
        }
        changeQty({ itemId, index, qty: newQty });
    };

    const buttonProps = [
        {
            label: 'Shipment',
            color: 'secondary',
            size: 'sm',
            icon: 'Truck',
            method: SHIPMENT_DELIVERY_METHOD,
            disabled: !hasAvailableOptions(shippingOptions),
        },
        {
            label: 'Pick Up',
            color: 'secondary',
            size: 'sm',
            icon: 'Archive',
            method: PICKUP_DELIVERY_METHOD,
            disabled: !hasAvailableOptions(inStoreOptions),
        },
        {
            label: 'Drop Ship',
            color: 'secondary',
            size: 'sm',
            icon: 'BoundingBox',
            method: DROPSHIP_DELIVERY_METHOD,
            disabled: !hasAvailableOptions(dropShipOptions),
        },
        {
            label: 'Cancel',
            color: 'danger',
            size: 'sm',
            icon: 'XCircle',
            method: CANCEL_METHOD,
        },
    ];

    const getOptionsForDeliveryMethod = (deliveryMethod) => {
        switch (deliveryMethod) {
            case SHIPMENT_DELIVERY_METHOD:
                return shippingOptions;
            case PICKUP_DELIVERY_METHOD:
                return inStoreOptions;
            case DROPSHIP_DELIVERY_METHOD:
                return dropShipOptions;
            default:
                return [];
        }
    };

    const getOptionsForSelect = ({ deliveryMethod, decisions }) => {
        const options = getOptionsForDeliveryMethod(deliveryMethod);

        return options.map(({ option_id, qty, label }) => {
            const usedOptionQty = reduce(
                decisions,
                (result, { id, qty }) => {
                    if (id === option_id) {
                        result += qty;
                    }
                    return result;
                },
                0,
            );
            const availableQty = qty - usedOptionQty;

            return {
                label,
                value: {
                    qty,
                    usedOptionQty,
                    availableQty,
                    option_id,
                },
                id: option_id,
                sublabel: `${availableQty} Available${usedOptionQty > 0 ? `. Currently used: ${usedOptionQty}` : ''}`,
                disabled: availableQty === 0,
            };
        });
    };

    return (
        <>
            {decisions.map(
                ({ qty, delivery_method: deliveryMethod, id: decisionId, price = null, vendorPrice = null }, index) => {
                    const options = getOptionsForSelect({ deliveryMethod, decisions });
                    return (
                        <ListingItems.Row
                            key={index}
                            className={classNames(styles.itemRow, {
                                [styles.child]: parentId,
                            })}
                        >
                            <ListingItems.Cell className={styles.productData}>
                                {parentId && (
                                    <span className="float-left mr-2 d-block">
                                        <SmstIcon icon="ArrowReturnRight" />
                                    </span>
                                )}
                                <ProductTag {...product} imageSrc={image?.url} route={productDetailsPageRoute} />
                            </ListingItems.Cell>
                            <ListingItems.Cell className={styles.qtyData} label="Qty">
                                <div className="text-center">
                                    {index === 0 && totalItemQty}
                                    {isSplitable && (
                                        <>
                                            {index === 0 && !isMaxed && (
                                                <Button size="sm" onClick={() => addSplit({ itemId, index })}>
                                                    Split
                                                </Button>
                                            )}
                                            {index !== 0 && (
                                                <Button
                                                    outline
                                                    onlyIcon
                                                    size="sm"
                                                    icon="Trash3"
                                                    onClick={() => removeSplit({ itemId, index })}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </ListingItems.Cell>
                            <ListingItems.Cell
                                className={classNames(styles.spltQtyData, {
                                    [styles.withOptionSelected]: deliveryMethod && !!options.length,
                                    [styles.withDropshipSelected]: deliveryMethod === DROPSHIP_DELIVERY_METHOD,
                                })}
                            >
                                {isSplitted && (
                                    <>
                                        <FormElements.FormLabel label="Split Qty" />
                                        <input
                                            className="text-center"
                                            onChange={(e) =>
                                                handleChange(e, { max: qty + freeQtyToSplit, index, itemId })
                                            }
                                            type="number"
                                            value={qty}
                                            max={qty + freeQtyToSplit}
                                        />
                                    </>
                                )}
                            </ListingItems.Cell>
                            <ListingItems.Cell className={styles.method}>
                                <div
                                    className={classNames(styles.methodButtonsWrapper, {
                                        [styles.withSplit]: isSplitted,
                                        [styles.withVendorPrice]: deliveryMethod === DROPSHIP_DELIVERY_METHOD,
                                    })}
                                >
                                    {buttonProps.map(({ method, label, ...restProps }) => (
                                        <Button
                                            key={method}
                                            {...restProps}
                                            outline={deliveryMethod !== method}
                                            onClick={() => changeMethod({ method, index, itemId })}
                                        >
                                            {label}
                                        </Button>
                                    ))}
                                </div>
                                {deliveryMethod && !!options.length && (
                                    <div className="mt-2 w-100">
                                        <Select
                                            onChange={({ value }) => changeLocation({ itemId, index, option: value })}
                                            value={find(options, ({ id }) => id === decisionId) || ''}
                                            options={options}
                                            isOptionDisabled={({ disabled = false }) => disabled}
                                        />
                                    </div>
                                )}
                            </ListingItems.Cell>
                            <ListingItems.Cell className={styles.vendorPrice}>
                                <VendorPrice
                                    itemId={itemId}
                                    vendorId={deliveryMethod === DROPSHIP_DELIVERY_METHOD ? decisionId : null}
                                    productId={get(product, 'id')}
                                    index={index}
                                    deliveryMethod={deliveryMethod}
                                    changeVendorPrice={changeVendorPrice}
                                    errorNotification={errorNotification}
                                    value={vendorPrice}
                                />
                            </ListingItems.Cell>
                            <ListingItems.Cell className={styles.itemPrice} label="Item Price">
                                {price}
                            </ListingItems.Cell>
                            <ListingItems.Cell className={styles.totalPrice} label="Total Price">
                                {(qty * price).toFixed(2)}
                            </ListingItems.Cell>
                        </ListingItems.Row>
                    );
                },
            )}
        </>
    );
}

Item.propTypes = {
    item: PropTypes.shape({
        itemId: PropTypes.string.isRequired,
        product: PropTypes.object,
        image: PropTypes.object,
        totalItemQty: PropTypes.number,
        customValues: PropTypes.array,
        decisions: PropTypes.array,
        shippingOptions: PropTypes.array,
        inStoreOptions: PropTypes.array,
        dropShipOptions: PropTypes.array,
    }),
    routes: PropTypes.shape({
        productDetailsPageRoute: PropTypes.string,
    }),
    addSplit: PropTypes.func,
    removeSplit: PropTypes.func,
    changeQty: PropTypes.func,
    changeVendorPrice: PropTypes.func,
    changeMethod: PropTypes.func,
    changeLocation: PropTypes.func,
    errorNotification: PropTypes.func,
};

Item.defaultProps = {
    item: {},
    routes: {},
    addSplit: () => {},
    removeSplit: () => {},
    changeQty: () => {},
    changeVendorPrice: () => {},
    changeMethod: () => {},
    changeLocation: () => {},
    errorNotification: () => {},
};

export default Item;
