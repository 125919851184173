import React from 'react';

import usePriceInput from './usePriceInput';

function PriceInput(props) {
    const { errorNotification, ...restProps } = props;
    const [{ value, isError }, { handleChange }] = usePriceInput(props);

    return (
        <div className="text-danger w-100">
            <input {...restProps} className={isError ? 'is-invalid' : ''} onChange={handleChange} value={value} />
            {isError && (
                <div style={{ display: 'block' }} className="mt-0 invalid-feedback">
                    This field is required
                </div>
            )}
        </div>
    );
}

PriceInput.propTypes = {};

PriceInput.defaultProps = {};

export default PriceInput;
