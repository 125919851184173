import customCustomerDetails from './customCustomerDetails';
import customOrderDetails from './customOrderDetails';
import customOrderForm from './customOrderForm';
import customOrderDecisionRules from './customOrderDecisionRules';
import customProductGrid from './customProductGrid';
import customPurchaseOrderOverview from './customPurchaseOrderOverview';

export default {
    customCustomerDetails,
    customOrderDetails,
    customOrderDecisionRules,
    customOrderForm,
    customProductGrid,
    customPurchaseOrderOverview,
};
