import { useState } from 'react';
import { ajaxExceptionMessage } from '@skunexus/utils';
import * as API from './api';

const useCustomerUpdate = ({
    errorNotification = () => {},
    successNotification = () => {},
    onCancel = () => {},
    onSuccess = () => {},
}) => {
    const [isSubmitting, setSubmitting] = useState(false);

    const updateCustomer = async ({ customerId, email }) => {
        setSubmitting(true);

        try {
            await API.updateCustomer({
                id: customerId,
                data: {
                    new_email: email,
                },
            });
            setSubmitting(false);
            successNotification('Customer email is updated.');
            onSuccess({ customerId, email });
        } catch (e) {
            setSubmitting(false);
            errorNotification(ajaxExceptionMessage(e));
            onCancel();
        }
    };

    return [
        {
            isSubmitting,
        },
        { updateCustomer },
    ];
};

export default useCustomerUpdate;
