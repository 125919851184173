/* eslint-disable camelcase */
import { get, find, findIndex, has, flatten, isEmpty, reduce, result } from 'lodash';
import { constants } from '@skunexus/order-details';

const { SHIPMENT_DELIVERY_METHOD, PICKUP_DELIVERY_METHOD, DROPSHIP_DELIVERY_METHOD, CANCEL_METHOD } = constants;

const NO_PARENT_ITEMS = 'orphan';

export const INIT_ACTION = 'init';
export const ADD_SPLIT_ACTION = 'addSplit';
export const REMOVE_SPLIT_ACTION = 'removeSplit';
export const CHANGE_QTY_ACTION = 'changeQty';
export const CHANGE_METHOD_ACTION = 'changeMethod';
export const CHANGE_METHOD_ALL_ACTION = 'changeMethodAll';
export const CHANGE_LOCATION_ACTION = 'changeLocation';
export const CHANGE_LOCATION_ALL_ACTION = 'changeLocationAll';
export const CHANGE_PRICE_ACTION = 'changeItemPrice';
export const CHANGE_VENDOR_PRICE_ACTION = 'changeItemVendorPrice';

const generateOptions = (collection = {}, method) => result(find(collection, { name: method }), 'options', []);

function addSplit(state, payload) {
    const { itemId: currentItemId, index } = payload;

    if (Number.isNaN(index) || !currentItemId) {
        return [...state];
    }

    return state.map((item) => {
        const { itemId, decisions = [] } = item;
        if (currentItemId !== itemId) {
            return item;
        }

        return {
            ...item,
            decisions: [
                ...decisions.slice(0, index + 1),
                { delivery_method: null, id: null, qty: 0 },
                ...decisions.slice(index + 1),
            ],
        };
    });
}

function removeSplit(state, payload) {
    const { itemId: currentItemId, index } = payload;

    if (Number.isNaN(index) || !currentItemId) {
        return [...state];
    }

    return state.map((item) => {
        const { itemId, decisions = [] } = item;
        if (currentItemId !== itemId) {
            return item;
        }

        return {
            ...item,
            decisions: [...decisions.slice(0, index), ...decisions.slice(index + 1)],
        };
    });
}

function changeQty(state, payload) {
    const { itemId: currentItemId, index, qty: newQty } = payload;

    if (Number.isNaN(index) || !currentItemId) {
        return [...state];
    }

    return state.map((item) => {
        const { itemId, decisions = [] } = item;
        if (currentItemId !== itemId) {
            return item;
        }

        const newDecisions = [...decisions];
        const decision = newDecisions[index];
        const { delivery_method, id } = decision;

        const decisionId = CANCEL_METHOD === delivery_method ? itemId : id;
        newDecisions[index] = { ...decision, qty: newQty, id: decisionId };
        return {
            ...item,
            decisions: newDecisions,
        };
    });
}

function changePrice(state, payload) {
    const { itemId: currentItemId, index, price: newPrice } = payload;

    if (Number.isNaN(index) || !currentItemId) {
        return [...state];
    }

    return state.map((item) => {
        const { itemId, decisions = [] } = item;
        if (currentItemId !== itemId) {
            return item;
        }

        const newDecisions = [...decisions];
        const decision = newDecisions[index];
        const { delivery_method, id } = decision;

        const decisionId = CANCEL_METHOD === delivery_method ? itemId : id;
        newDecisions[index] = { ...decision, price: newPrice, id: decisionId };
        return {
            ...item,
            decisions: newDecisions,
        };
    });
}

function changeVendorPrice(state, payload) {
    const { itemId: currentItemId, index, price: newPrice } = payload;

    if (Number.isNaN(index) || !currentItemId) {
        return [...state];
    }

    return state.map((item) => {
        const { itemId, decisions = [] } = item;
        if (currentItemId !== itemId) {
            return item;
        }

        const newDecisions = [...decisions];
        const decision = newDecisions[index];
        const { delivery_method, id } = decision;

        const decisionId = CANCEL_METHOD === delivery_method ? itemId : id;
        newDecisions[index] = { ...decision, vendorPrice: newPrice, id: decisionId };
        return {
            ...item,
            decisions: newDecisions,
        };
    });
}

function changeMethod(state, payload) {
    const { itemId: currentItemId, index, method } = payload;

    if (Number.isNaN(index) || !currentItemId) {
        return [...state];
    }

    return state.map((item) => {
        const { itemId, decisions = [] } = item;
        if (currentItemId !== itemId) {
            return item;
        }

        const newDecisions = [...decisions];
        const decision = newDecisions[index];
        const { delivery_method } = decision;

        const newDeliveryMethod = delivery_method === method ? null : method;
        const decisionId = CANCEL_METHOD === newDeliveryMethod ? itemId : null;

        newDecisions[index] = { ...decision, delivery_method: newDeliveryMethod, id: decisionId, vendorPrice: '' };

        return {
            ...item,
            decisions: newDecisions,
        };
    });
}

function changeMethodAll(state, method) {
    return state.map((item) => ({
        ...item,
        decisions: get(item, 'decisions', []).map((decision) => ({
            ...decision,
            delivery_method: method,
            id: null,
        })),
    }));
}

function changeLocation(state, payload) {
    const { itemId: currentItemId, index, option = {} } = payload;
    const { option_id: decisionId, availableQty } = option;

    if (Number.isNaN(index) || !currentItemId) {
        return [...state];
    }

    return state.map((item) => {
        const { itemId, decisions = [] } = item;
        if (currentItemId !== itemId) {
            return item;
        }

        let shouldSplitLine = false;
        const newDecisions = decisions.map((decision, i) => {
            if (i === index) {
                const { qty } = decision;
                let newQty = qty;
                if (qty > availableQty) {
                    newQty = availableQty;
                    shouldSplitLine = true;
                }
                return { ...decision, id: decisionId, qty: decisionId ? newQty : 0 };
            }
            return decision;
        });

        if (shouldSplitLine) {
            return {
                ...item,
                decisions: [
                    ...newDecisions.slice(0, index + 1),
                    { delivery_method: null, id: null, qty: 0 },
                    ...newDecisions.slice(index + 1),
                ],
            };
        }

        return {
            ...item,
            decisions: [...newDecisions],
        };
    });
}

function changeLocationAll(state, location) {
    return state.map((item) => ({
        ...item,
        decisions: get(item, 'decisions', []).map((decision) => ({
            ...decision,
            id: location,
        })),
    }));
}

const getParentData = (item) => {
    const { parentRelatedProduct = {}, customValues = {} } = item;
    const { id: parentId, name: parentName, sku: parentSku, weight: parentWeight } = parentRelatedProduct;
    const parentQty = get(find(customValues, ['custom_field_id', 'parent_qty']), 'value', 0);

    return {
        itemId: parentId,
        product: {
            name: parentName,
            weight: parentWeight,
            sku: parentSku,
            id: parentId,
        },
        image: {},
        totalItemQty: parentQty,
        shippingOptions: [],
        inStoreOptions: [],
        dropShipOptions: [],
        decisions: [],
        customValues,
    };
};

const getChildrenData = (item) => {
    const { decidable_qty, id, decisionOptions, image, relatedProduct, product_id, customValues = [] } = item;
    const { name, weight, sku } = relatedProduct;

    const itemPrice = get(find(customValues, ['custom_field_id', 'item_price']), 'value', '');

    return {
        itemId: id,
        product: {
            name,
            weight,
            sku,
            id: product_id,
        },
        image,
        totalItemQty: decidable_qty,
        shippingOptions: generateOptions(decisionOptions, SHIPMENT_DELIVERY_METHOD),
        inStoreOptions: generateOptions(decisionOptions, PICKUP_DELIVERY_METHOD),
        dropShipOptions: generateOptions(decisionOptions, DROPSHIP_DELIVERY_METHOD),
        decisions: [{ id: null, delivery_method: null, qty: decidable_qty, price: itemPrice }],
        customValues,
    };
};

function getFormattedItems(items = []) {
    const groupedItems = reduce(
        items.filter(({ decidable_qty }) => decidable_qty > 0),
        // eslint-disable-next-line no-shadow
        (result, item) => {
            const { parentRelatedProduct = {}, product_id } = item;

            const hasParent = !isEmpty(parentRelatedProduct) && product_id !== get(parentRelatedProduct, 'id');

            if (hasParent) {
                const { id: parentId } = parentRelatedProduct;

                let parentIndex = findIndex(result, ({ parent }) => parent.itemId === parentId);
                if (parentIndex < 0) {
                    result.push({
                        parent: getParentData(item),
                        children: [],
                    });
                }
                parentIndex = findIndex(result, ({ parent }) => parent.itemId === parentId);
                // eslint-disable-next-line no-param-reassign
                result[parentIndex].children = [...result[parentIndex].children, getChildrenData(item)];
            } else {
                let parentIndex = findIndex(result, ({ parent }) => parent.itemId === NO_PARENT_ITEMS);
                if (parentIndex < 0) {
                    result.push({
                        parent: {
                            itemId: NO_PARENT_ITEMS,
                        },
                        children: [],
                    });
                }

                parentIndex = findIndex(result, ({ parent }) => parent.itemId === NO_PARENT_ITEMS);
                // eslint-disable-next-line no-param-reassign
                result[parentIndex].children = [...result[parentIndex].children, getChildrenData(item)];
            }

            return result;
        },
        [],
    );

    // return groupedItems;
    return flatten(
        groupedItems
            .filter(({ children = [] }) => children.length)
            .map(({ parent = {}, children = [] }) => {
                if (parent.itemId === NO_PARENT_ITEMS) {
                    return [...children.map((item) => ({ ...item, isChild: true }))];
                }

                return [
                    {
                        ...parent,
                    },
                    ...children.map((child) => ({
                        parentId: get(parent, 'itemId'),
                        isChild: has(parent, 'product.name'),
                        ...child,
                    })),
                ];
            }),
    );
}

function itemsReducer(state, { payload, type }) {
    switch (type) {
        case INIT_ACTION:
            return getFormattedItems(payload);
        case ADD_SPLIT_ACTION:
            return addSplit(state, payload);
        case REMOVE_SPLIT_ACTION:
            return removeSplit(state, payload);
        case CHANGE_QTY_ACTION:
            return changeQty(state, payload);
        case CHANGE_PRICE_ACTION:
            return changePrice(state, payload);
        case CHANGE_VENDOR_PRICE_ACTION:
            return changeVendorPrice(state, payload);
        case CHANGE_METHOD_ACTION:
            return changeMethod(state, payload);
        case CHANGE_METHOD_ALL_ACTION:
            return changeMethodAll(state, payload);
        case CHANGE_LOCATION_ACTION:
            return changeLocation(state, payload);
        case CHANGE_LOCATION_ALL_ACTION:
            return changeLocationAll(state, payload);
        default: {
            return state;
        }
    }
}

export default itemsReducer;
