import { useEffect, useState, useRef } from 'react';
import { get } from 'lodash';

const useForm = ({ items: initItems }) => {
    const formRef = useRef(null);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const formattedItems = initItems.map(({ id, product = {}, quantity, price, replacementProducts = [] }) => ({
            id,
            isChecked: false,
            product,
            qty: quantity,
            itemPrice: price,
            replacementProducts,
            items: [
                {
                    qty: quantity,
                    itemPrice: price,
                    vendorPrice: '',
                    resolveType: null,
                    vendorId: null,
                    replacementProductId: null,
                },
            ],
        }));

        setItems(formattedItems);
    }, [initItems]);

    const handleCheck = ({ id, value }) => {
        setItems((state) =>
            state.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        isChecked: value,
                    };
                }
                return item;
            }),
        );
    };

    const handleSplit = ({ id }) => {
        setItems((state) =>
            state.map((item) => {
                if (item.id === id) {
                    const { itemPrice } = item;
                    return {
                        ...item,
                        items: item.items.concat([
                            {
                                qty: 0,
                                itemPrice,
                                vendorPrice: '',
                                resolveType: null,
                                vendorId: null,
                                replacementProductId: null,
                            },
                        ]),
                    };
                }
                return item;
            }),
        );
    };

    const handleRemoveSubItem = ({ id, index }) => {
        setItems((state) =>
            state.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        items: item.items.filter((i, _index) => _index !== index),
                    };
                }
                return item;
            }),
        );
    };

    const handlePriceChange = ({ id, index, price }) => {
        setItems((state) =>
            state.map((item) => {
                if (item.id === id) {
                    const newItems = [...item.items];

                    newItems[index].itemPrice = price;
                    return {
                        ...item,
                        items: newItems,
                    };
                }
                return item;
            }),
        );
    };

    const handleQtyChange = ({ id, index, qty }) => {
        setItems((state) =>
            state.map((item) => {
                if (item.id === id) {
                    const newItems = [...item.items];

                    newItems[index].qty = qty;
                    return {
                        ...item,
                        items: newItems,
                    };
                }
                return item;
            }),
        );
    };

    const handleVendorChange = ({ id, index, vendorId }) => {
        setItems((state) =>
            state.map((item) => {
                if (item.id === id) {
                    const newItems = [...item.items];

                    newItems[index].vendorId = vendorId;
                    return {
                        ...item,
                        items: newItems,
                    };
                }
                return item;
            }),
        );
    };

    const handleVendorPriceChange = ({ id, index, price }) => {
        setItems((state) =>
            state.map((item) => {
                if (item.id === id) {
                    const newItems = [...item.items];

                    newItems[index].vendorPrice = price;
                    return {
                        ...item,
                        items: newItems,
                    };
                }
                return item;
            }),
        );
    };

    const handleProductChange = ({ id, index, productId }) => {
        setItems((state) =>
            state.map((item) => {
                if (item.id === id) {
                    const newItems = [...item.items];

                    newItems[index].replacementProductId = productId;
                    newItems[index].vendorId = null;
                    newItems[index].vendorPrice = '';
                    return {
                        ...item,
                        items: newItems,
                    };
                }
                return item;
            }),
        );
    };

    return [
        { formRef, items },
        {
            handleCheck,
            handleSplit,
            handleRemoveSubItem,
            handlePriceChange,
            handleQtyChange,
            handleVendorChange,
            handleVendorPriceChange,
            handleProductChange,
        },
    ];
};

export default useForm;
