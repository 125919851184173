import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { Row, Col } from 'reactstrap';
import { Card, Header } from '@skunexus/component-catalog';

import { FulfillmentButton, CancelConfirmModal, MassFulfillemntMethodButtons, Footer } from '@skunexus/order-details';

import ItemsHeader from './components/ItemsHeader';
import Item from './components/Item';
import useDecidableItems from './useDecidableItems';
import styles from './styles.module.css';

function DecidableItems(props) {
    const { isLoading, isEditable, routes = {}, errorNotification = () => {} } = props;

    const [
        { decidableItems = [], totalItems = 0, totalWeight = 0, isSubmitting = false },
        {
            addSplit = () => {},
            removeSplit = () => {},
            changeQty = () => {},
            changeMethod = () => {},
            changeMethodAll = () => {},
            changeLocation = () => {},
            changeLocationAll = () => {},
            changePrice = () => {},
            changeVendorPrice = () => {},
            handleCreateFulfillment,
            getCancelModalProps,
        },
    ] = useDecidableItems(props);

    if (!decidableItems.length) return null;

    const childrenDecidableItems = decidableItems.filter(({ isChild = false }) => isChild);

    return (
        <Row className="mt-3">
            <Col>
                <Card
                    title="Assign Products for Fulfillment"
                    isLoading={isLoading}
                    noPadding
                    className={styles.card}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    HeaderComponent={({ children, ...restProps }) => (
                        <>
                            <Header {...restProps}>{children}</Header>
                            <MassFulfillemntMethodButtons
                                items={childrenDecidableItems}
                                changeMethodAll={changeMethodAll}
                                changeLocationAll={changeLocationAll}
                            />
                        </>
                    )}
                >
                    <div className={styles.itemsWrapper}>
                        <ItemsHeader />
                        {decidableItems.map((item) => (
                            <Item
                                key={item.id}
                                item={item}
                                routes={routes}
                                addSplit={addSplit}
                                removeSplit={removeSplit}
                                changeQty={changeQty}
                                changeMethod={changeMethod}
                                changeLocation={changeLocation}
                                changePrice={changePrice}
                                changeVendorPrice={changeVendorPrice}
                                errorNotification={errorNotification}
                                childrenItems={decidableItems.filter(
                                    ({ isChild, parentId }) => isChild && parentId === get(item, 'itemId'),
                                )}
                            />
                        ))}
                    </div>
                    <Footer totalWeight={totalWeight} totalItems={totalItems}>
                        {({ totalWeightLabel, totalQtyLabel }) => (
                            <>
                                <div>{totalQtyLabel}</div>
                                <div className={styles.totalWeightWithButtonWrapper}>
                                    {totalWeightLabel}
                                    <FulfillmentButton
                                        items={childrenDecidableItems}
                                        isLoading={isSubmitting}
                                        isEditable={isEditable}
                                        onClick={handleCreateFulfillment}
                                    />
                                </div>
                            </>
                        )}
                    </Footer>
                </Card>
                <CancelConfirmModal {...getCancelModalProps()} />
            </Col>
        </Row>
    );
}

DecidableItems.propTypes = {
    items: PropTypes.array,
    isLoading: PropTypes.bool,
    isEditable: PropTypes.bool,
    routes: PropTypes.shape({
        productDetailsPageRoute: PropTypes.string,
    }),
    errorNotification: PropTypes.func,
};

DecidableItems.defaultProps = {
    items: [],
    isLoading: false,
    isEditable: false,
    routes: {},
    errorNotification: () => {},
};

export default DecidableItems;
