const useAddedProducts = ({ products = [], onChangeProducts = () => {} }) => {
    const handleChangeItemQty = ({ id, qty }) => {
        const newProducts = [...products].map((item) => {
            const { id: productId } = item;

            if (productId === id) {
                return {
                    ...item,
                    qty,
                };
            }

            return item;
        });

        onChangeProducts({ name: 'line_items', value: newProducts });
    };
    const handleChangeItemPrice = ({ id, price }) => {
        const newProducts = [...products].map((item) => {
            const { id: productId } = item;

            if (productId === id) {
                return {
                    ...item,
                    price,
                };
            }

            return item;
        });

        onChangeProducts({ name: 'line_items', value: newProducts });
    };
    const handleChangeChildrenPrice = ({ id, childId, price }) => {
        const newProducts = [...products].map((item) => {
            const { id: productId, children = [] } = item;

            if (productId === id) {
                return {
                    ...item,
                    children: [...children].map((child) => {
                        const { id: _childId } = child;

                        if (_childId === childId) {
                            return {
                                ...child,
                                price,
                            };
                        }

                        return child;
                    }),
                };
            }

            return item;
        });

        onChangeProducts({ name: 'line_items', value: newProducts });
    };
    const handleRemoveItem = (id) => {
        onChangeProducts({ name: 'line_items', value: products.filter(({ id: productId }) => productId !== id) });
    };

    return [
        {
            items: products,
        },
        {
            handleChangeItemQty,
            handleChangeItemPrice,
            handleChangeChildrenPrice,
            handleRemoveItem,
        },
    ];
};
export default useAddedProducts;
