import React from 'react';
import PropTypes from 'prop-types';

import { FormElements } from '@skunexus/component-catalog';
import usePriceInput from './usePriceInput';

function PriceInput({ errorNotification, label, ...props }) {
    const [{ value, isError }, { handleChange }] = usePriceInput({ errorNotification, ...props });

    return (
        <div className="text-danger w-100">
            {label && <FormElements.FormLabel label={label} />}
            <input {...props} className={isError ? 'is-invalid' : ''} onChange={handleChange} value={value} />
            {isError && (
                <div style={{ display: 'block' }} className="mt-0 invalid-feedback">
                    This field is required
                </div>
            )}
        </div>
    );
}

PriceInput.propTypes = {
    errorNotification: PropTypes.func,
    label: PropTypes.string,
};

PriceInput.defaultProps = {
    errorNotification: () => {},
    label: '',
};

export default PriceInput;
