import { useEffect, useCallback, useState } from 'react';
import { debounce, isNumber } from 'lodash';

const usePriceInput = ({ min = 0, value: propsValue = '', onChange = () => {}, errorNotification = () => {} }) => {
    const [value, setValue] = useState();
    const [isError, setErrorFlag] = useState(false);

    useEffect(() => {
        setValue(propsValue);
    }, [propsValue]);

    useEffect(() => {
        setErrorFlag(value === '');
    }, [value]);

    const debouncedOnChange = useCallback(debounce(onChange, 500), [onChange]);

    const handleChange = (e) => {
        // eslint-disable-next-line no-shadow
        const { value } = e.target;

        const price = value ? parseFloat(value) : value;

        if (isNumber(price) && price < min) {
            errorNotification(`Price can't be less then ${min}.`);

            return;
        }

        setValue(price);
        debouncedOnChange(price);
    };

    return [{ value, isError }, { handleChange }];
};

export default usePriceInput;
