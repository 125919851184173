import { useState } from 'react';
import { get } from 'lodash';
import * as API from './api';

const useChildrenProducts = () => {
    const [products, setProducts] = useState({
        data: [],
        isLoading: false,
        error: null,
    });

    const fetchChildrenProducts = async (ids = []) => {
        setProducts((state) => ({
            ...state,
            isLoading: true,
            error: null,
        }));
        try {
            const result = await API.fetchProducts(ids);

            const data = get(result, 'data.data.product.grid.rows');

            if (!data) {
                throw new Error(`Couldn't load products.`);
            }

            setProducts((state) => ({
                ...state,
                data,
                isLoading: false,
            }));

            return data;
        } catch (e) {
            setProducts((state) => ({
                ...state,
                isLoading: false,
                error: e,
            }));

            return null;
        }
    };

    return [
        {
            products: products.data,
            error: products.error,
            isLoading: products.isLoading,
        },
        { fetchChildrenProducts },
    ];
};

export default useChildrenProducts;
