import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ParentSpaceProvider, SubspaceProvider } from 'react-redux-subspace';
import values from 'lodash/values';
import get from 'lodash/get';
import MainTabs from '@skunexus/fulfillment-main-tabs';

import app from '../../../../config';
import UserModel from '../../../../UserModel';
import { firstAllowedLink, filterAccessibleItems, combinePermissions } from '../../../../utils/helpers';
import { getDefaultAssignUserValue } from '../utils';

function MainTabsLayout({ children, name, ...props }) {
    const mainTabsParamsConfig = get(app.general.getItem('modules'), 'fulfillment.mainTabsParamsConfig', {});
    const rolesForDefaultAssignedUser = get(
        app.general.getItem('modules'),
        'fulfillment.rolesForDefaultAssignedUser',
        [],
    );

    const states = () => {
        const fulfillmentShipTabs = app.fulfillmentShipTabs.getAll();

        if (fulfillmentShipTabs.waves) {
            const fulfillmentWavesTabs = values(app.fulfillmentWavesTabs.getAll());

            fulfillmentShipTabs.waves.link = firstAllowedLink(fulfillmentWavesTabs);
            fulfillmentShipTabs.waves.permission = combinePermissions(fulfillmentWavesTabs);
        }

        if (fulfillmentShipTabs.pick) {
            fulfillmentShipTabs.pick.config = {
                countable: true,
                countGraphqlFilterPart: 'current_state: {operator: eq, value: "pick"}',
            };
        }

        if (fulfillmentShipTabs.pack) {
            fulfillmentShipTabs.pack.config = {
                countable: true,
                countGraphqlFilterPart: 'current_state: {operator: eq, value: "pack"}',
            };
        }

        if (fulfillmentShipTabs.groupPick) {
            fulfillmentShipTabs.groupPick.config = {
                countable: true,
                countGraphqlFilterPart:
                    'type: { operator: like, value: "%manual%" }, state: { operator: neq, value: "picked" }',
                gridField: 'groupGrid',
            };
        }

        if (fulfillmentShipTabs.waves) {
            fulfillmentShipTabs.waves.config = {
                countable: true,
                countGraphqlFilterPart:
                    'type: { operator: like, value: "%wave%" }, state: { operator: neq, value: "picked" }',
                gridField: 'groupGrid',
            };
        }

        return values(fulfillmentShipTabs);
    };
    return (
        <div>
            <ParentSpaceProvider>
                <SubspaceProvider
                    mapState={(state) => ({
                        ...state.fulfillmentMainTabs,
                        ...state[name],
                    })}
                >
                    <>
                        <MainTabs
                            currentUserId={UserModel.getUserId()}
                            states={filterAccessibleItems(states)}
                            defaultAssignUserValue={getDefaultAssignUserValue({
                                allowedRoles: rolesForDefaultAssignedUser,
                            })}
                            {...mainTabsParamsConfig}
                            {...props}
                        />
                        {children}
                    </>
                </SubspaceProvider>
            </ParentSpaceProvider>
        </div>
    );
}

MainTabsLayout.propTypes = {
    children: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
};

export default MainTabsLayout;
