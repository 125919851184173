import { constants, gql } from '@skunexus/utils';

const {
    CONFIG: { API_PATH },
} = constants;

export function fetchProducts(search) {
    return gql.getGraphQLGrid({
        url: `${API_PATH}/query`,
        config: {
            domain: 'product',
        },
        gridQueryPart: `grid (
          sort: {name: ASC},
          filter: {
            fulltext_search:  "%${search}%",
          }
        ) { 
            rows { 
              id
              sku
              name
              is_kit
              qty_on_hand
              customValues {
                custom_field_id
                value
              }
              relatedProduct {
                id: related_product_id
                qty
                name
                sku
              }
          }
        }`,
    });
}
