import { useEffect } from "react";

import { ajaxExceptionMessage } from "@skunexus/utils";

import useModal from "./hooks/useModal";
import useItems from "./hooks/useItems";
import useForm from "./hooks/useForm";
import useResolve from "./hooks/useResolve";

const useResolvePurchaseOrder = ({ details, errorNotification, successNotification }) => {
    const [{ isOpen }, { openModal, closeModal }] = useModal();
    const [{ items, error, isLoading }, { fetchItems, reset }] = useItems();
    const [
        { formRef, items: resolveItems },
        {
            handleCheck,
            handleSplit,
            handleRemoveSubItem,
            handlePriceChange,
            handleQtyChange,
            handleVendorChange,
            handleVendorPriceChange,
            handleProductChange,
        },
    ] = useForm({ items });

    const onSuccess = () => {
        window.location.reload();
    };

    const [{ isSubmitting }, { isResolveAllowed, resolve }] = useResolve({
        poId: details.id,
        onSuccess,
        errorNotification,
        successNotification,
    });

    useEffect(() => {
        if (error) {
            errorNotification(ajaxExceptionMessage(error));
        }
    }, [error]);

    useEffect(() => {
        if (isOpen) {
            fetchItems(details.id);
        } else {
            reset();
        }
    }, [isOpen]);

    const handleConfirm = () => {
        formRef.current.submit();
    };

    const handleSubmit = () => {
        resolve(resolveItems);
    };

    return [
        {
            formRef,
            isOpen,
            isLoading,
            isSubmitting,
            isDisabled: !isResolveAllowed(resolveItems),
            items: resolveItems,
        },
        {
            handleConfirm,
            handleSubmit,
            handleCheck,
            handleSplit,
            handleRemoveSubItem,
            handlePriceChange,
            handleQtyChange,
            handleVendorChange,
            handleVendorPriceChange,
            handleProductChange,
            handleOpenModal: openModal,
            handleCloseModal: closeModal,
        },
    ];
};

export default useResolvePurchaseOrder;
