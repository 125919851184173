import {
    FulfillmentMethods,
    fulfillmentMethodsUtils,
    fulfillmentMethodsConstants,
} from '@skunexus/order-decision-rules-form';

export default {
    name: 'customOrderDecisionRules',
    before: [],
    after: [],
    setup: (app) => {
        const remedOrderDecisionActionName = 'remed_order_decision_action';
        const routeName = 'orderDecisionRuleForm';
        const route = app.routes.getItem(routeName);
        if (route) {
            const { AVAILABLE_METHODS, METHODS_CONFIG } = fulfillmentMethodsConstants;

            const customActionComponents = {
                [remedOrderDecisionActionName]: FulfillmentMethods,
            };

            const customActionUtils = {
                [remedOrderDecisionActionName]: fulfillmentMethodsUtils,
            };
            const customActionProps = {
                [remedOrderDecisionActionName]: {
                    availableMethods: [...AVAILABLE_METHODS],
                    methodsConfig: {
                        ...METHODS_CONFIG,
                    },
                },
            };

            const routeProps = route.props;
            route.props = (state) => ({
                ...routeProps(state),
                customActionComponents,
                customActionUtils,
                customActionProps,
            });

            app.routes.changeItem(routeName, route);
        }
    },
};
