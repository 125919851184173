import { useEffect } from 'react';

import { OVERALL_TOTAL_PARTS } from '../../hooks/useCustomAttributes';

export const useCustomAttributes = ({ domain, customFields = [], fetchCustomFields, onChangeField }) => {
    useEffect(() => {
        fetchCustomFields({ domain });
    }, []);

    const handleCustomFieldChange = ({ value, field }) => {
        const isOverallTotalPartChange =
            OVERALL_TOTAL_PARTS.filter((fieldName) => field.includes(fieldName)).length > 0;

        if (isOverallTotalPartChange) {
            onChangeField({ name: field, value: value.target.value });
        }
    };

    const getMappedCustomFieldId = (fieldId) => `customValues[${fieldId}]`;

    const getMappedCustomFields = (fields = []) => fields.map(getMappedCustomFieldId);

    return {
        customFields: customFields.map(({ id, ...rest }) => ({
            id: getMappedCustomFieldId(id),
            gridConfig: {
                xs: 12,
                md: 6,
                xl: 4,
            },
            ...rest,
        })),
        getMappedCustomFields,
        handleCustomFieldChange,
    };
};
