import { connect } from 'react-redux';

import { actions as notification } from '@skunexus/notification';

import Component from './DecidableItems';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    errorNotification: notification.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
