import { constants, gql } from '@skunexus/utils';

const {
    CONFIG: { API_PATH },
} = constants;

export function fetchVendorProduct({ productId, vendorId }) {
    return gql.getGraphQLGrid({
        url: `${API_PATH}/query`,
        config: {
            domain: 'vendorProduct',
        },
        gridQueryPart: `grid (
          filter: {
            product_id: { operator: eq, value: "${productId}" },
            vendor_id: { operator: eq, value: "${vendorId}" }
          }
        ) { 
            rows { 
              id 
              label
              price
            
          }
        }`,
    });
}
