import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectors } from '@skunexus/purchase-order-overview';
import { actions as notification } from '@skunexus/notification';

import Components from './ResolvePurchaseOrder';

const { getDetails } = selectors;

const mapStateToProps = createStructuredSelector({
    details: getDetails,
});

const mapDispatchToProps = {
    errorNotification: notification.error,
    successNotification: notification.success,
};

export default connect(mapStateToProps, mapDispatchToProps)(Components);
