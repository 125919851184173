import React from 'react';
import useInput from './useInput';

function Input(props) {
    const { errorNotification, ...restProps } = props;
    const [{ value, isError }, { handleChange }] = useInput(props);

    return (
        <div className="text-danger">
            <input
                {...restProps}
                className={isError ? 'is-invalid' : ''}
                onChange={handleChange}
                value={value}
                required
            />
            {isError && (
                <div style={{ display: 'block' }} className="mt-0 invalid-feedback">
                    This field is required
                </div>
            )}
        </div>
    );
}

Input.propTypes = {};

Input.defaultProps = {};

export default Input;
