export const PURCHASE_ORDER_STATUSES = {
    ACTIVE: 'active',
    PENDING: 'pending',
    DRAFT: 'draft',
    CANCELLED: 'cancelled',
    CLOSED: 'closed',
    ERROR: 'error',
};

export const CANCEL_RESOLVE_OPTION = 'cancel';
