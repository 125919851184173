import { useEffect } from 'react';
import { get, find, flatten } from 'lodash';
import { ajaxExceptionMessage } from '@skunexus/utils';
import useChildrenProducts from '../useChildrenProducts';

const useGroupedProducts = ({ errorNotification = () => {} }) => {
    const [{ error, isLoading }, { fetchChildrenProducts }] = useChildrenProducts();

    useEffect(() => {
        if (error) {
            errorNotification(ajaxExceptionMessage(error));
        }
    }, [error]);

    const getGroupedProducts = async (products, selectedProducts) => {
        const childrenToGet = flatten(products.map(({ children = [] }) => children.map(({ id }) => id)));

        if (childrenToGet.length) {
            const childrenProducts = await fetchChildrenProducts(childrenToGet);

            return [
                ...selectedProducts,
                // eslint-disable-next-line no-shadow
                ...products.map(({ children = [], ...product }) => ({
                    ...product,
                    children: children.map((child) => {
                        const { id } = child;
                        const childData = find(childrenProducts, ['id', id]);
                        const { customValues = [] } = childData;

                        return {
                            ...child,
                            price: get(find(customValues, ['custom_field_id', 'price']), 'value', 0),
                        };
                    }),
                })),
            ];
        }

        return [...selectedProducts, ...products];
    };

    return [{ isLoading }, { getGroupedProducts }];
};

export default useGroupedProducts;
