import { useEffect, useCallback, useState } from 'react';
import { debounce, isNumber } from 'lodash';

const useQtyInput = ({
    min = 1,
    max = 1,
    value: propsValue = '',
    onChange = () => {},
    errorNotification = () => {},
}) => {
    const [value, setValue] = useState('');
    const [isError, setErrorFlag] = useState(false);

    useEffect(() => {
        setValue(propsValue);
    }, [propsValue]);

    useEffect(() => {
        setErrorFlag(value === '');
    }, [value]);

    const debouncedOnChange = useCallback(debounce(onChange, 500), [onChange]);

    const handleChange = (e) => {
        // eslint-disable-next-line no-shadow
        const { value } = e.target;

        const qty = value ? parseInt(value, 10) : value;

        if (isNumber(qty) && qty > max) {
            errorNotification(`Cannot assign more than was ordered.`);
            setValue(max);
            onChange(max);
            return;
        }

        if (isNumber(qty) && qty < min) {
            errorNotification(`Quantity can't be less then ${min}.`);
            return;
        }

        setValue(qty);
        debouncedOnChange(qty);
    };

    return [{ value, isError }, { handleChange }];
};

export default useQtyInput;
