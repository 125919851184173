/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { get, sumBy } from 'lodash';
import { Button, FormItem, ListingItems, ProductTag } from '@skunexus/component-catalog';

import PriceInput from './components/PriceInput';
import QtyInput from './components/QtyInput';
import Actions from './components/Actions';

import styles from '../../styles.module.css';

function Item(props) {
    const {
        item,
        index,
        onCheck = () => {},
        onSplit = () => {},
        onRemoveSubItem = () => {},
        onPriceChange = () => {},
        onQtyChange = () => {},
        onVendorChange = () => {},
        onVendorPriceChange = () => {},
        onProductChange = () => {},
        errorNotification = () => {},
    } = props;

    const { id, isChecked, product = {}, qty = 0, items = [], replacementProducts } = item;

    const isSplitted = items.length > 1;
    const freeQtyToManage = qty - sumBy(items, 'qty');

    return (
        <>
            {items.map((subItem, subItemIndex) => {
                const { qty: subItemQty, vendorPrice = 0, itemPrice = 0, vendorId, replacementProductId } = subItem;
                const isMain = subItemIndex === 0;
                return (
                    <ListingItems.Row className={styles.itemRow}>
                        <ListingItems.Cell className={styles.checkboxData}>
                            {isMain && (
                                <FormItem
                                    showLabel={false}
                                    type="checkbox"
                                    name={`items[${id}].selected`}
                                    onChange={(e) => {
                                        onCheck({
                                            id,
                                            value: e.target.checked,
                                        });
                                    }}
                                    value={isChecked}
                                />
                            )}
                        </ListingItems.Cell>
                        <ListingItems.Cell className={styles.productData}>
                            {isMain && <ProductTag {...product} />}
                        </ListingItems.Cell>
                        <ListingItems.Cell className={styles.qtyData}>
                            <div className={styles.qtyWrapper}>
                                {isMain && qty}
                                {isMain && isChecked && qty > 1 && (
                                    <Button size="sm" onClick={() => onSplit({ id })}>
                                        Split
                                    </Button>
                                )}
                            </div>
                        </ListingItems.Cell>
                        <ListingItems.Cell className={styles.priceData}>
                            {!isChecked && itemPrice}
                            {isChecked && (
                                <PriceInput
                                    min={1}
                                    type="number"
                                    name={`items[${id}][${subItemIndex}].itemPrice`}
                                    value={itemPrice}
                                    onChange={(price) => onPriceChange({ id, index: subItemIndex, price })}
                                    errorNotification={errorNotification}
                                />
                            )}
                        </ListingItems.Cell>
                        <ListingItems.Cell className={styles.splitQtyData}>
                            <div className={styles.splitQtyWrapper}>
                                <div className={styles.splitQtyInputWrapper}>
                                    {(isChecked || isSplitted) && qty > 1 && (
                                        <QtyInput
                                            min={0}
                                            max={subItemQty + freeQtyToManage}
                                            type="number"
                                            name={`items[${id}][${subItemIndex}].itemPrice`}
                                            value={subItemQty}
                                            onChange={(qty) => onQtyChange({ id, index: subItemIndex, qty })}
                                            errorNotification={errorNotification}
                                        />
                                    )}
                                    {!isMain && (
                                        <Button
                                            className="ml-2"
                                            outline
                                            icon="Trash3"
                                            onlyIcon
                                            onClick={() => onRemoveSubItem({ id, index: subItemIndex })}
                                        />
                                    )}
                                </div>

                                {/* {isMain && freeQtyToManage > 0 && (
                                    <div>
                                        <p className={styles.splitErrorMessage}>Split QTY must equal total QTY</p>
                                    </div>
                                )} */}
                            </div>
                        </ListingItems.Cell>
                        <ListingItems.Cell className={styles.actions}>
                            {isChecked && (
                                <Actions
                                    id={id}
                                    index={subItemIndex}
                                    replacementProductId={replacementProductId}
                                    replacementProducts={replacementProducts}
                                    productId={get(product, 'id')}
                                    vendorId={vendorId}
                                    vendorPrice={vendorPrice}
                                    onVendorChange={(vendorId) => onVendorChange({ id, index: subItemIndex, vendorId })}
                                    onVendorPriceChange={(price) =>
                                        onVendorPriceChange({ id, index: subItemIndex, price })
                                    }
                                    onProductChange={(productId) =>
                                        onProductChange({ id, index: subItemIndex, productId })
                                    }
                                    errorNotification={errorNotification}
                                />
                            )}
                        </ListingItems.Cell>
                    </ListingItems.Row>
                );
            })}
        </>
    );
}

Item.propTypes = {
    errorNotification: PropTypes.func.isRequired,
};

Item.defaultProps = {};

export default Item;
