/* eslint-disable camelcase */
import { findIndex } from 'lodash';
import { CANCEL_RESOLVE_OPTION } from '../../../../../../../constants';

const useCancellation = ({ replacementProducts = [] }) => {
    const isCancelOptionEnabled =
        findIndex(replacementProducts, ({ product_id }) => product_id === CANCEL_RESOLVE_OPTION) >= 0;
    return [{ isCancelOptionEnabled }, {}];
};

export default useCancellation;
